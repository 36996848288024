import { Auth } from 'aws-amplify';
import { useCallback } from 'react';
import { API_URL } from '@utils/constants';

function parseJSON(response) {
  return new Promise((resolve) =>
    response.json().then((body) =>
      resolve({
        status: response.status,
        ok: response.ok,
        body,
      })
    )
  );
}

export default function useAPI() {
  const apiCall = useCallback(async (url = '', method = 'GET', body) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((data) => data.getIdToken().getJwtToken())
        .then((token) =>
          fetch(`${API_URL}${url}`, {
            method: method,
            body: JSON.stringify(body),
            headers: {
              'content-type': 'application/json',
              'x-api-key': import.meta.env.VITE_API_KEY,
              Authorization: `Bearer ${token}`,
            },
          })
        )
        .then(parseJSON)
        .then((response) => {
          console.log(`${method} ${url}: `, response.body);
          if (response.ok) {
            resolve(response.body);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          console.error(`${method} ${url}: `, err);
          reject(err);
        });
    });
  }, []);

  return { apiCall };
}
