import classnames from 'classnames';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import { StyledButton, useStyles } from './styles';

const Button = ({
  id,
  label,
  type = 'button',
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  position,
  classes = {},
  onClick = () => {},
  icon = <></>,
  disabled = false,
  loading = false,
  ariaLabel = '',
}) => {
  const styles = useStyles();

  return (
    <StyledButton
      data-cy={id || ''}
      {...{
        color,
        variant,
        size,
        disabled,
        loading,
        classes,
        ['aria-label']: ariaLabel,
      }}
      className={classnames({
        [`position-${position}`]: !!position,
        [styles.disabled]: disabled,
        [styles.loading]: loading,
      })}
      type={type}
      onClick={onClick}
    >
      {variant !== 'icon' && <span>{label}</span>}

      {variant === 'icon' && <>{icon}</>}

      {loading && (
        <div>
          <Spinner size={16} />
        </div>
      )}
    </StyledButton>
  );
};

Button.propTypes = {
  /** This id is used by Cypress to detect the button during tests. */
  id: PropTypes.string,
  /** Content displayed inside the button. It is inserted in a <span/> tag. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Button type. Default value is 'button'. */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Color theme applied to the whole button. */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'alert',
    'cancel',
    'orange',
    'transparent',
  ]),
  /** Type of button. */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'link', 'icon']),
  /** Button size. */
  size: PropTypes.oneOf(['small', 'medium']),
  /** Position of the button in a row. */
  position: PropTypes.oneOf(['left', 'right']),
  /** Object with classes to extend and/or override. */
  classes: PropTypes.object,
  /** Function to execute when button is clicked. */
  onClick: PropTypes.func,
  /** React node to display icon if variant = 'icon'. */
  icon: PropTypes.element,
  /** If true button is disabled. */
  disabled: PropTypes.bool,
  /** If true a spinner is displayed inside the button */
  loading: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default Button;
