import { Button } from '@UI';
import { ArrowLeft } from '@UI/Icons';
import Dropbox from 'components/Dropbox/Dropbox';
import { ALLERGENS } from '@constants';
import { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './DishForm.module.scss';
import DishPhotoPlaceholder from '../DishPhotoPlaceholder/DishPhotoPlaceholder';
import { convertToBase64 } from '@utils/helpers';
import {
  Checkbox,
  Chip,
  TextAreaInput,
  TextInput,
  NumberInput,
} from '@fjpedrosa/malaga-ui';
import {
  useCreateDishMutation,
  useUpdateDishMutation,
  useUploadDishImageMutation,
} from '@store/services/dishService';

const DishForm = ({ isNew, dishData }) => {
  const { categories, menusMap, categoriesMap } = useSelector(
    (state) => state.config
  );
  const { dishId } = useParams();

  const [createDish] = useCreateDishMutation();
  const [updateDish] = useUpdateDishMutation();
  const [uploadDishImage, { isLoading: isUploadingImage }] =
    useUploadDishImageMutation();

  const [loadingApi, setLoadingApi] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'allergens',
  });

  const name = watch('name');
  const allergens = watch('allergens');
  const selectedCategories = useMemo(() => watch('categories') || [], [watch]);

  // const categoriesOptions = useMemo(
  //   () =>
  //     categories
  //       .map((category) => ({
  //         value: category.id,
  //         label: category.name,
  //       }))
  //       .filter(
  //         (option) => !selectedCategories.some((el) => el === option.value)
  //       ),
  //   [selectedCategories, categories]
  // );

  const goBack = () => navigate(-1);

  const onSubmit = (form) => {
    setLoadingApi(true);

    const apiMethod = isNew ? createDish : updateDish;

    apiMethod({ ...form, dishId })
      .then(() => setLoadingApi(false))
      .catch((err) => console.error('## ERROR on POST /dish: ', err));
  };

  const handleUploadFiles = async (files, lastLoadingState = false) => {
    const dataUrl = await convertToBase64(files).then((res) => res[0]);
    return uploadDishImage({ dishId, imgData: dataUrl });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={styles.header}>
        <Button
          variant="icon"
          icon={<ArrowLeft height={28} width={28} />}
          onClick={goBack}
          classes={{ button: styles.headerBack }}
        />
        <div>
          <Button
            label="Guardar"
            type="submit"
            disabled={!name}
            loading={loadingApi}
          />
        </div>
      </div>
      <div className={styles.imageSection}>
        {dishData?.imageRoute ? (
          <div
            className={styles.coverImage}
            style={{ backgroundImage: `url('${dishData.imageRoute}')` }}
          />
        ) : (
          <Dropbox
            onFileUpload={handleUploadFiles}
            placeholder={<DishPhotoPlaceholder />}
            loading={isUploadingImage}
            classes={{ container: styles.coverImage }}
          />
        )}
      </div>

      <div className={styles.body}>
        <div>
          {errors.name && (
            <div className={styles.inputError}>
              Por favor, rellena este campo
            </div>
          )}
        </div>
        <TextInput
          register={register}
          name="name"
          label="Nombre del plato"
          placeholder={`Introduce el nombre de la categoría, p.e. "Desayunos"`}
          variant="standard"
        />
        <TextAreaInput
          register={register}
          name="description"
          label="Descripción"
          classes={{
            container: styles.textareaContainer,
            input: styles.textareaInput,
          }}
          variant="standard"
        />
        <div className={styles.row}>
          <NumberInput
            register={register}
            name="price"
            label="Precio"
            variant="standard"
            adornment="€"
            placeholder="p.e. 12,5 €"
          />
          {/* <Select
            title="Categorías"
            placeholder="Añadir a categoría"
            chipSelector
            isSearcheable
            onChange={(value) =>
              setValue('categories', [...selectedCategories, value])
            }
            options={categoriesOptions}
            classes={{
              container: { maxWidth: 190 },
            }}
          /> */}
        </div>

        <div className={styles.categories}>
          {selectedCategories.map((categoryId) => (
            <Chip
              key={`chip-${categoryId}`}
              label={
                categories.find((category) => category.id === categoryId).name
              }
              onDelete={() =>
                setValue(
                  'categories',
                  selectedCategories.filter((el) => el !== categoryId)
                )
              }
            />
          ))}
        </div>
        <div className={styles.menus}>
          {selectedCategories.length > 0 && (
            <span>
              <b>Aparecerá en los siguientes menus: </b>
              {[
                ...new Set(
                  selectedCategories
                    .reduce(
                      (acc, curr) =>
                        (acc = [...acc, ...categoriesMap[curr].menus]),
                      []
                    )
                    .map((menuId) => menusMap[menuId].name)
                ),
              ].map((el) => (
                <>{`${el} `}</>
              ))}
            </span>
          )}
        </div>
      </div>
      <div className={styles.allergensContainer}>
        {ALLERGENS.map((el) => {
          return (
            <Checkbox
              key={`allergen-${el.name}`}
              name={el.name}
              value={el.name}
              checked={allergens?.includes(el.name) || false}
              onChange={(e) => {
                if (e.target.checked) {
                  append(e.target.value);
                } else {
                  const itemIndex = fields
                    .map((el) => {
                      delete el.id;
                      return Object.values(el).join('');
                    })
                    .indexOf(e.target.value);
                  remove(itemIndex);
                }
              }}
              label={
                <>
                  {el.icon} {el.label}
                </>
              }
              classes={{
                container: styles.allergensItem,
                label: styles.allergensItemLabel,
              }}
            />
          );
        })}
      </div>
    </form>
  );
};

export default DishForm;
