import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';

const SidebarRoute = ({ icon, label, link = '', onClick = undefined }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const Icon = icon;
  return (
    <div
      className={classNames('sidebar__section--route', {
        selected: location.pathname === link,
      })}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(link);
        }
      }}
    >
      <div className="sidebar__section--route-link">
        <Icon style={{ color: 'white' }} />
        <span>{label}</span>
      </div>
    </div>
  );
};

export default SidebarRoute;
