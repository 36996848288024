import { useState } from 'react';
import Select from '@UI/Select';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import {
  hours,
  optionsHours,
  pxPerHour,
  pxPerMin,
  weekDays,
} from './constants';
import { useStyles } from './styles';
import { Checkbox } from '@fjpedrosa/malaga-ui/dist/cjs';

const Timetable = ({ register, setValue, getValues, watch, defaultValues }) => {
  const [duration, setDuration] = useState({ hours: 0, minutes: 0 });

  const { days, from, to, allDays = false, allHours = false } = watch();

  const styles = useStyles();

  const timesStyles = {
    width:
      allHours || !duration
        ? 24 * pxPerHour
        : duration.hours * pxPerHour + duration.minutes * pxPerMin,
    left:
      allHours || !from
        ? 0
        : DateTime.fromISO(from).hour * pxPerHour +
          DateTime.fromISO(from).minute * pxPerMin,
  };

  const calculateDuration = (from, to) => {
    const calcDuration = DateTime.fromISO(to).minus({
      hours: DateTime.fromISO(from).hour,
      minutes: DateTime.fromISO(from).minute,
    });

    const hours = calcDuration.hour;
    const minutes = calcDuration.minute;
    setDuration({
      hours: hours === 0 && minutes === 0 ? 24 : hours,
      minutes: minutes,
    });
  };

  const handleDayClick = (day) => {
    let days = getValues('days');
    if (days.includes(day)) {
      days = days.filter((el) => el !== day);
    } else {
      days.push(day);
    }
    setValue('days', days);
  };

  const handleToHourChange = (value) => {
    setValue('to', value);
    if (from) calculateDuration(from, value);
  };

  const handleFromHourChange = (value) => {
    setValue('from', value);
    if (to) calculateDuration(value, to);
  };

  return (
    <div className={styles.timetable}>
      <span className={styles.title}>Horarios del menú</span>
      <div className={styles.selector}>
        <div className={styles.days}>
          {weekDays.map((day) => (
            <div
              key={day}
              onClick={() => handleDayClick(day)}
              className={classNames(styles.day, {
                selected: days.includes(day) || allDays,
              })}
            >
              {day}
            </div>
          ))}
          <Checkbox
            name="allDays"
            refs={register('allDays').ref}
            title="Seleccionar todos los días"
            hideErrors
            classes={{ container: { marginLeft: 36 } }}
            defaultChecked={!!defaultValues.allDays || false}
            onChange={(e) => setValue('allDays', e.target.checked)}
          />
        </div>
        <div className={styles.hours}>
          <div className={styles.timeCell}>
            {hours.slice(0, -1).map((time, index) => (
              <div
                key={time}
                className={classNames(styles.time, {
                  'last-item': index === 7,
                })}
              />
            ))}
            <div className={styles.selectedTime} style={timesStyles} />
          </div>
          <div className={styles.timeCell}>
            {hours.map((time) => (
              <div key={`hour-${time}`} className={styles.hour}>
                {time}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.timeSelect}>
          <Select
            refs={register('from').ref}
            title="Desde las"
            placeholder="Seleccione una hora"
            inputValue={allHours ? optionsHours[0].value : undefined}
            options={optionsHours.slice(0, -1).filter((el) => {
              if (to) {
                return DateTime.fromISO(el.value) < DateTime.fromISO(to);
              } else {
                return true;
              }
            })}
            onChange={(value) => {
              handleFromHourChange(value);
              setValue('from', value);
            }}
            classes={{
              container: styles.selectContainer,
              option: {
                textAlign: 'left',
              },
            }}
            disabled={!!allHours}
          />
          <Select
            refs={register('to').ref}
            title="Hasta las"
            placeholder="Seleccione una hora"
            inputValue={allHours ? optionsHours.slice(-1)[0].value : undefined}
            options={optionsHours.slice(1).filter((el) => {
              if (from) {
                return DateTime.fromISO(el.value) > DateTime.fromISO(from);
              } else {
                return true;
              }
            })}
            onChange={(value) => {
              handleToHourChange(value);
              setValue('to', value);
            }}
            classes={{
              container: styles.selectContainer,
              option: {
                textAlign: 'left',
              },
            }}
            disabled={!!allHours}
          />
          <Checkbox
            name="allHours"
            refs={register('allHours').ref}
            title="Disponible 24 horas"
            hideErrors
            classes={{ container: { marginLeft: 36, width: 'auto' } }}
            defaultChecked={!!defaultValues.allHours || false}
            onChange={(e) => {
              setValue('allHours', e.target.checked);
            }}
            singleCol
          />
        </div>
      </div>
    </div>
  );
};

export default Timetable;
