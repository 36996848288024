import { FC } from 'react';
import { CircularProgress } from '@mui/material';

interface LoaderProps {
  size?: number;
  height?: string;
}

const Loader: FC<LoaderProps> = ({ size = 80, height = '100%' }) => {
  return (
    <div style={{ display: 'grid', placeContent: 'center', height }}>
      <CircularProgress size={size} />
    </div>
  );
};

export default Loader;
