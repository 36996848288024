const MilkAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
  >
    <path
      fill="#6D351E"
      d="M184.4,31.6C164,11.2,136.8,0,108,0C79.2,0,52,11.2,31.6,31.6S0,79.2,0,108s11.2,56,31.6,76.4S79.2,216,108,216 c28.8,0,56-11.2,76.4-31.6C204.8,164,216,136.8,216,108S204.8,52,184.4,31.6z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M119.5,45.2l11.2-3.8l8.2-2.1l5.3,0.2l4,1.3l3.3,3.1l0.3,3.8 l-0.7,2.6l-2.8,2.3l-4.8,2.5l-3.5,2.1l-2,2.6l-1.3,5.6l-1,4.6l1,7.1l3.6,4.8l4.9,6.9l5.4,11.7l2.3,7.6l2.1,13.4l0.7,9.4l-0.5,9.1 l-1.8,9.9l-3,7.1l-3.5,5.9l-4,6.4l-3.8,3.8l-4.6,2.6l-8.4,1l-22.8-0.3l-7.3-0.7l-3.5-0.8l-2.8-1.8l-3.6-4l-7.8-11.5l-3-8.2l-2-7.4 l-0.3-7.4l0.2-3.1l-2.5-3.6l-3.6-6.6l-3.3-6.8l-3-10.2l-1.2-5.8l0.8-8.2l1.5-8.1l3.1-6.6l6.1-6.8l6.3-3.5l6.8-2.3l-2.8-5.6L81,53.2 l0.7-2.8l3.6-2.6l7.3-2.1l7.8-1.2h5.9h5.8l3.5,0.5l-0.7,0.3l-3,0.8h-4l-4.8,0.3l-6.8,0.7l-5.1,1.6l-3,1.6l0.8,1.6l3.3,0.8l4.9,0.7 l2.1-0.2l4.6-1.6l7.8-3.3l5.6-2.5L119.5,45.2z"
    />
    <path
      fill="#6D351E"
      stroke="#6D351E"
      strokeWidth={0.2788}
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M74,118.3l1.2-7.8l2.6-8.7l3.1-8l2.8-5.2l2.4-3.6l0.7-1.3 l1.1-3.1l0.2-5l-0.6-3.5l-0.7-0.9l-1.2-0.6L84.8,71L80,73.6l-3.4,2.5L72,81l-2.5,4.2l-1.7,5.3L67.3,96l0.6,5.4l1,5l1.6,4.6l1.6,3.5 L74,118.3z"
    />
    <path
      fill="#6D351E"
      stroke="#6D351E"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M95.2,63.6l2.2-0.7l2.5-0.1l1.5,0.3l0.8,1.7l0.3,2.6v5.2 l-0.2,4.7l-0.7,4.6l-1.5,3.6l-0.9,1.8l-1.3,2.6l-1.6,2.6l-2.5,4l-1.6,3.9l-2.8,6.3l-2,5.8l-1.3,3.2l-1.1,2.6L84,119l-0.9-0.2 l-0.6-0.4l-0.3-1.6l1.5-7.9l2-5.9l2.8-7.6l2.9-5.4l2.4-4.6l1.6-3.7l0.5-3.1L95.1,72l-0.4-4.8l-0.1-1.9L95.2,63.6z"
    />
  </svg>
);

export default MilkAllergensIcon;
