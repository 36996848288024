// This functions returns object nested stringified props
// i.e. object['prop1.prop2[3].prop3']
export const deepValue = (obj, path) =>
  path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter((s) => s)
    .reduce((acc, val) => acc && acc[val], obj);

export function numberWithCommas(x, lang) {
  const decimalSymbol = lang === 'es' ? ',' : '.';
  const thousandSymbol = lang === 'es' ? '.' : ',';

  if (x) {
    var parts = x.toString().split(decimalSymbol);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSymbol);
    return parts.join(decimalSymbol);
  } else {
    return x;
  }
}
