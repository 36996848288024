import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// import IngredientsList from '../../../../../components/Dishes/IngredientsList';
import DishForm from '@features/Dish/forms/DishForm/DishForm';
import styles from './DishDetails.module.scss';
import { useGetDishByIdQuery } from '@store/services/dishService';
import { CircularProgress } from '@mui/material';

const DishDetails = () => {
  const { dishId } = useParams();
  const { data: dishData, isLoading } = useGetDishByIdQuery(dishId);
  const dishIsNew = dishData?.statusCode === 400;

  const formMethods = useForm();

  useEffect(() => {
    if (!isLoading && !dishIsNew && dishData?.data) {
      formMethods.reset(dishData?.data);
    }
  }, [isLoading, dishIsNew, dishData, formMethods]);

  if (isLoading) return <CircularProgress size={80} />;

  return (
    <FormProvider {...formMethods}>
      <div className={styles.form}>
        <DishForm isNew={dishIsNew} dishData={dishData.data} />
      </div>
      {/* <IngredientsList /> */}
    </FormProvider>
  );
};

export default DishDetails;
