import PropTypes from 'prop-types';

const UploadCloudIcon = ({
  width = 32,
  height = 32,
  color = '#8C9299',
  classes,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 21.3333L16 16L10.6666 21.3333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16V28"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1866 24.5199C28.4871 23.8109 29.5144 22.6891 30.1064 21.3314C30.6985 19.9737 30.8216 18.4575 30.4562 17.0221C30.0909 15.5868 29.2579 14.3139 28.0888 13.4045C26.9198 12.4951 25.4811 12.0009 23.9999 11.9999H22.3199C21.9164 10.4389 21.1642 8.98968 20.1199 7.76122C19.0756 6.53277 17.7664 5.55703 16.2907 4.90737C14.8151 4.25772 13.2113 3.95104 11.6001 4.01041C9.98886 4.06977 8.41204 4.49363 6.98818 5.25012C5.56433 6.0066 4.3305 7.07603 3.37944 8.37801C2.42839 9.67998 1.78487 11.1806 1.49725 12.7671C1.20964 14.3536 1.28542 15.9846 1.7189 17.5376C2.15238 19.0906 2.93227 20.525 3.99995 21.7332"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 21.3333L16 16L10.6666 21.3333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

UploadCloudIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  classes: PropTypes.string,
};

export default UploadCloudIcon;
