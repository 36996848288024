import styled, { css } from 'styled-components';
import { media } from '../variables/variables';

export const respondTo = Object.keys(media).reduce((accumulator, label) => {
  const condition = Object.keys(media[label]).reduce((acc, prop, index) => {
    acc += `${index === 0 ? '' : ' and'} ${prop}: ${media[label][prop]}`;
    return acc;
  }, ``);

  accumulator[label] = (...args) => css`
    @media only screen and (${condition}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const container = () => css`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
  width: 100%;

  ${respondTo.tablet`
    padding: 0 50px;
  `}

  ${respondTo.desktop_big`
    max-width: 1000px;
  `}

  ${respondTo.desktop_extra_big`
    max-width: 1200px;
  `}

  ${respondTo.desktop_1600`
    max-width: 1400px;
  `}
`;

export const font_text = () => css`
  font-family: 'Raleway Light';
`;

export const font_text_regular = () => css`
  font-family: 'Raleway Regular';
  font-weight: 100;
`;

export const font_text_bold = () => css`
  font-family: 'Raleway Semi Bold';
  font-weight: 100;
`;
export const font_headline = () => css`
  font-family: 'Raleway Extra Bold';
  font-weight: 100;
`;

export const Button = ({ children }) => {
  return <StyledButton>{children}</StyledButton>;
};

export const StyledButton = styled.a`
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  line-height: 54px;
  min-height: 54px;
  overflow: hidden;
  padding: 0 30px;
  position: relative;
  color: #fff;
  margin-top: 0;
  height: 54px;
  background: #2793ff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 100px;

  &::after {
    display: none;
  }

  span {
    position: relative;
    z-index: 2;
  }

  ${respondTo.desktop`
    justify-content: space-between;
    width: 100%;
  `}

  &::before {
    background: rgba(255, 255, 255, 0.15);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s ease-in-out;
    z-index: 1;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  span {
    @include ff_headline();
    font-size: 18px;
    line-height: 18px;
  }
`;

export const link = () => css``;
