import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Summary from '@views/Summary';
import DishDetails from '@features/Dish/presentation/pages/DishDetails/DishDetails';
import DishesList from '@features/Dish/presentation/pages/DishesList/DishesList';
import CreateCategory from 'components/Categories/CreateCategory';
import CreateMenu from 'components/Menus/CreateMenu';
import ViewMenus from 'components/Menus/ViewMenus';
import Nutrition from 'components/Nutrition';
import PrivateRoutes from '@containers/PrivateRoutes';
import ListCategories from '@features/Category/presentation/pages/ListCategories/ListCategories';
import { lazy } from 'react';
import ProfilePage from '@features/User/presentation/pages/ProfilePage/ProfilePage';

const CartaUsuario = lazy(
  () => import('@features/Carta/presentation/pages/CartaUsuario/CartaUsuario')
);
const Carta = lazy(() => import('@views/Carta'));
const Menus = lazy(() => import('@views/Menus'));
const DishesLayout = lazy(
  () => import('@features/Dish/presentation/layout/DishesLayout/DishesLayout')
);
const CategoriesLayout = lazy(
  () => import('@features/Category/presentation/layout/CategoriesLayout')
);
const QREditor = lazy(() => import('@features/QR/presentation/pages/QREditor'));

export default createBrowserRouter([
  {
    path: '/carta',
    element: <Outlet />,
    children: [
      {
        path: ':userId',
        element: <CartaUsuario />,
      },
    ],
  },
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'dashboard',
        element: <Carta />,
        children: [
          {
            path: 'menu',
            element: <Menus />,
            children: [
              {
                path: '',
                element: <ViewMenus />,
              },
              {
                path: 'create',
                element: <CreateMenu />,
              },
              {
                path: ':menuId',
                element: <CreateMenu />,
              },
            ],
          },
          {
            path: 'categorias',
            element: <CategoriesLayout />,
            children: [
              {
                path: '',
                element: <ListCategories />,
              },
              {
                path: 'create',
                element: <CreateCategory />,
              },
              {
                path: ':categoryId',
                element: <CreateCategory />,
              },
            ],
          },
          {
            path: 'platos',
            element: <DishesLayout />,
            children: [
              {
                path: '',
                element: <DishesList />,
              },
              {
                path: ':dishId',
                element: <DishDetails />,
              },
            ],
          },
          {
            path: '',
            element: <Summary />,
          },
        ],
      },
      { path: 'qr', element: <QREditor /> },
      { path: 'perfil', element: <ProfilePage /> },
      { path: 'nutricion', element: <Nutrition /> },
      { path: '', element: <Navigate to="/dashboard" /> }, // Para la navegación
    ],
  },
]);
