import { useState, useEffect } from 'react';
import Timetable from 'components/Timetable';
import useAPI from '@hooks/useAPI';
import { useForm } from 'react-hook-form';
import Button from '@UI/Button';
import { ArrowLeft, DocumentIcon } from '@UI/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMenus } from '@store/slices/configSlice';

const CreateMenu = () => {
  const [loadingApi, setLoadingApi] = useState(false);

  const dispatch = useDispatch();
  const { apiCall } = useAPI();
  const { menuId } = useParams();
  const navigate = useNavigate();

  const menu = useSelector((state) =>
    state.config.menus.find((menu) => menu.id === menuId)
  );

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: menu?.name || undefined,
      days: menu?.days || [],
      from: menu?.from || null,
      to: menu?.to || null,
      allDays: menu?.allDays || false,
      allHours: menu?.allHours || false,
    },
  });

  const name = watch('name');

  useEffect(() => {
    if (menuId && !menu) navigate('/dashboard/menu/create');
  }, [menu, menuId, navigate]);

  const goBack = () => navigate(-1);

  const onSubmit = (form) => {
    setLoadingApi(true);

    let url = '/dashboard/menu';
    if (menuId) url += `/${menuId}`;

    apiCall(url, menu ? 'PATCH' : 'POST', form)
      .then(() => dispatch(getMenus()))
      .catch((err) => console.error('## ERROR on POST /carta/menu: ', err))
      .finally(() => setLoadingApi(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="menus__header">
        <Button
          variant="icon"
          icon={<ArrowLeft height={28} width={28} />}
          onClick={goBack}
          classes={{ button: 'menus__header--back' }}
        />
        <div>
          <Button
            label="Guardar"
            type="submit"
            disabled={!name}
            loading={loadingApi}
          />
        </div>
      </div>
      <div className="menus__body">
        <input
          {...register('name', { required: true })}
          className="menus__body--input"
          type="text"
          placeholder={`Introduce el nombre del menu, p.e. "Menu del día"`}
        />
        {errors.name && (
          <div className="menus__body--input-error">
            Por favor, rellena este campo
          </div>
        )}
        <Button
          variant="text"
          classes={{ button: 'menus__body--notes' }}
          label={
            <div className="menus__body--notes-label">
              <DocumentIcon />
              Añadir nota
            </div>
          }
        />
        <Timetable
          defaultValues={{
            allHours: menu?.allHours || false,
            allDays: menu?.allDays || false,
          }}
          {...{ register, setValue, getValues, watch }}
        />
      </div>
    </form>
  );
};

export default CreateMenu;
