import { font } from 'assets/scss/helpers/fonts';
import { colors } from 'assets/scss/variables/variables';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 6,
    width: '100%',

    '&.hidden': {
      display: 'none',
    },

    extend: ({ classes }) =>
      typeof classes?.container === 'object' && classes.container,
  },
  control: {
    position: 'relative',
    width: '100%',

    extend: ({ classes }) =>
      typeof classes?.control === 'object' && classes.control,
  },

  label: {
    ...font.text,
    color: '#888',
    cursor: 'text',
    fontSize: 16,
    paddingLeft: 10,
    pointerEvents: 'none',
    position: 'absolute',
    top: 12,
    transition: 'all 0.2s',

    '& span': {
      color: colors.red,
      display: 'inline-block',
      paddingLeft: 5,
    },

    '&.active': {
      color: '#999',
      fontSize: 10,
      top: 4,
    },

    extend: ({ classes }) =>
      typeof classes?.label === 'object' && classes.label,
  },

  input: {
    ...font.text,
    appearance: 'none',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: 3,
    boxSizing: 'border-box',
    color: '#000',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: 16,
    height: 46,
    outline: 'none',
    padding: '11px 10px 0',
    transition: 'all 0.2s ease-in-out',
    width: '100%',

    // @include respond-to(desktop) {
    //   fontSize: 14
    // }

    '&:focus': {
      border: '1px solid #666',
    },

    '&::-webkit-calendar-picker-indicator': {
      cursor: 'pointer',
    },

    '&.readonly': {
      backgroundColor: '#f2f2f2',
      pointerEvents: 'none',
    },

    '&.error': {
      animationDuration: '0s',
      marginBottom: 0,
      marginTop: 0,
      paddingTop: 0,
      border: `1px solid ${colors.red}`,
    },
  },
  adornment: {
    maxWidth: 18,
    position: 'absolute',
    right: 18,
    top: 16,
  },
  error: {
    color: colors.red,
    fontSize: 12,
    margin: '2px 0 0',
    paddingLeft: 4,
  },
  emptyError: {
    height: 18,
  },
});
