import { Button, EditIcon } from '@fjpedrosa/malaga-ui';
import DishImagePlaceholder from '@UI/Icons/DishImagePlaceholder';
import { useNavigate } from 'react-router-dom';
import styles from './DishCard.module.scss';
import { ALLERGENS } from '@constants';
import { cloneElement } from 'react';
import classNames from 'classnames';

const DishCard = ({ dishData }) => {
  const { id, name, categories, description, price, imageRoute, allergens } =
    dishData;
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {imageRoute ? (
        <div
          className={styles.img}
          style={{ backgroundImage: `url('${dishData.imageRoute}')` }}
        />
      ) : (
        <div
          className={classNames(styles.img, styles.imgPlaceholder)}
          style={{ backgroundImage: `url('${dishData.imageRoute}')` }}
        >
          <DishImagePlaceholder width={92} height={92} />
        </div>
      )}
      <div className={styles.content}>
        <h2 className={styles.contentName}>{name}</h2>
        <span className={styles.contentDescription}>{description}</span>
        <div className={styles.contentHeader}>
          <div className={styles.contentAllergens}>
            {ALLERGENS.filter((el) => allergens?.includes(el.name)).map(
              (el) => (
                <>{cloneElement(el.icon, { height: 12, width: 12 })}</>
              )
            )}
          </div>
          <h3 className={styles.contentPrice}>{`Precio: ${
            price || '--'
          } €`}</h3>
        </div>
      </div>
      <Button
        label="Ver"
        variant="icon"
        icon={<EditIcon height={16} width={16} />}
        color="primary"
        onClick={() => navigate(`${id}`)}
      />
    </div>
  );
};

export default DishCard;
