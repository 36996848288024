import { colors } from 'assets/scss/variables/variables';

const DishIcon = ({ size = 122, color = colors.black }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 122.14 116.69"
    >
      <defs />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        d="M81.38,89.51l24.05-9.9a4.28,4.28,0,0,1,5.27,1.7l1.5,2.42a4.27,4.27,0,0,1-1.27,5.82L78.48,111.11A17.5,17.5,0,0,1,67.85,114l-39-2.14"
        transform="translate(-1.71 -4.5)"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        d="M19.4,91.33c7.91-12,26.86-15.85,34.89-6.31l15.05.82a3.31,3.31,0,0,1,3.13,3.49h0l-.28,5.07a3.31,3.31,0,0,1-3.48,3.12h0l-18.39-1"
        transform="translate(-1.71 -4.5)"
      />
      <rect
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        x="5.2"
        y="87.29"
        width="14.54"
        height="31.84"
        transform="translate(-17.34 -1.38) rotate(-8.79)"
      />
      <line
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        x1="4.96"
        y1="60.89"
        x2="121.14"
        y2="60.89"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        d="M116.93,66l-5.46,8.75H18.05l-5.47-8.07"
        transform="translate(-1.71 -4.5)"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        d="M108.09,65.39V55.57a43.33,43.33,0,0,0-86.66-.06v7.81"
        transform="translate(-1.71 -4.5)"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        d="M58.9,11.57a5.86,5.86,0,0,1,11.72-.42v.42"
        transform="translate(-1.71 -4.5)"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeWidth={2}
        strokeMiterlimit={10}
        d="M36,40.43A30.89,30.89,0,0,1,51.1,26.66"
        transform="translate(-1.71 -4.5)"
      />
    </svg>
  );
};

export default DishIcon;
