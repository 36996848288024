const SojaAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#02A75B"
  >
    <g>
      <ellipse cx="108" cy="108" rx="108" ry="108" />
      <g transform="translate(-30.434321,315.16539)">
        <g>
          <path
            fill="#FEFEFE"
            stroke="#FEFEFE"
            strokeWidth={0.2788}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={22.3065}
            d="M65.6-155.4l4.6,3.2l4.7,2.1l5.8,1.7l4.9,1.2l7.7,1.2l5.1,0.7l4.6,0.1l5.8-0.3l9.1-1.6l7.3-1.2l6-1.5l10.7-2.7l6-1.8l8.7-3 l6.9-2.1c2.2-0.7,4.2-1.1,6.2-1.5c2.1-0.5,4.4-0.7,6.8-0.8c3.4-0.1,5.9,0.1,8.9,0.8c1.9,0.4,3.8,1.1,5.7,1.9 c1.9,1,3.8,1.9,5.7,3.4c1.8,1.1,3.2,2.6,4.7,3.9l5.6,5.8c1.9,2.4,4.1,4.7,5.6,7.1l1.9,2.1c0.2-0.1,0.4-0.2,0.6-0.6 c0.7,0.6,1,1.2,1.3,1.8c-0.6,0-1.3,0.1-1.9,0.2l-2.2-0.5l-4.5-0.8l-6.7-0.1l-6.6,0.5l-8.7,1.6c-3.1,0.7-6.4,1.8-9.6,2.8 l-15.9,5.4c-6.7,2.8-13.9,4.8-20.7,7.3l-10,1.9l-9.3,1.7l-6.8,0.8l-11,0.2l-8.7-1.2c-2.4-0.7-4.8-1.4-7.1-2.6 c-3.3-1.3-5.6-3.3-8.2-5.2c-2.8-2.9-5.7-5.8-7.1-9.2L69-137l-1.9-5.9l-0.6-3.7l-0.3-4.5l-0.2-1.4L65.6-155.4z"
          />
          <g>
            <path
              fill="#02A75B"
              stroke="#02A75B"
              strokeWidth={0.2788}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={22.3065}
              d="M77.7-132.6l-0.3-1.9l-0.1-0.9l0.4-2l0.8-2.5l0.7-1.2l1.4-1l1.7-0.9l1.9-0.4l1.7-0.1l2.5,0.5l2.3,1.1l2.5,1.5l2.3,2l1.7,2.6 l1.8,3.5l0.6,3.3l-0.1,2.5l-1.2,3.1c-0.3,0.4-0.6,0.7-0.9,0.9l-0.4-0.1l-1.6,1.2l-1.4,0.8l-4.3-0.1l-3.1-1l-2.7-1.5l-2.3-2 l-1.8-2.2l-1.2-2.3l-0.6-1.3L77.7-132.6z"
            />
            <path
              fill="#02A75B"
              stroke="#02A75B"
              strokeWidth={0.2788}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={22.3065}
              d="M101.3-130.9l-0.4-1.2c-0.1-0.5-0.5-1-0.2-1.6l0-2.3c0-1.1,0.6-2.3,0.9-3.2c0.3-0.6,0.9-1.1,1.5-1.5l0.9-0.9 c0.3-0.3,0.9-0.5,1.5-0.6c0.6-0.2,0.6-0.3,2.1-0.5l2.1,0l2.2,0.4c1,0.4,2.1,0.6,3.1,1.2l2.7,1.7l1.9,1.7l1.9,2.4 c0.8,1.2,1.5,2.6,2,3.8c0.3,0.9,0.4,1.7,0.5,2.6c0.1,1.4,0.1,2.7-0.2,4c-0.1,1.2-0.6,2.1-1.2,3c-0.3,0.4-0.5,0.7-0.8,0.9 l-0.6,0.6l-1.4,0.7l-1.9,0.6c-1.4,0.4-2.8,0.1-4.3,0.1l-3.2-1.1c-0.9-0.5-2.1-0.8-2.8-1.7c-0.7-0.2-1.5-1.5-2.2-2.2l-1.9-2 l-1.1-1.7l-0.9-1.7L101.3-130.9z"
            />
            <path
              fill="#02A75B"
              stroke="#02A75B"
              strokeWidth={0.2788}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={22.3065}
              d="M125.8-133.2l-0.5-1.4l-0.1-1.5c-0.3-1.3-0.1-2,0-2.9l0.9-2.2l1-1.3c0.4-0.5,0.8-1.1,1.3-1.3c0.7-0.4,1.5-0.8,2.1-1l2-0.4l2-0.1 c1,0.2,2.1,0.3,3.1,0.5c0.9,0.3,1.9,0.6,2.4,0.9c0.9,0.5,1.9,1,2.6,1.6c1.1,0.8,1.8,1.5,2.4,2.2c0.8,0.8,1.4,1.3,2.4,2.5 c0.9,1.7,2,3.4,2.2,5c0.1,1.2,0.4,2.6,0.2,3.1c0,1-0.4,1.8-0.8,2.5c-0.5,1.1-1.1,2.1-1.9,2.9c-0.3,0.4-0.7,0.7-1,0.9l-0.6,0.4 l-1.5,0.4l-1.4,0.7c-1.4,0.3-2.9,0.1-4.3-0.1l-3.1-1l-2.7-1.5l-2.3-2l-1.8-2.2l-1.2-2.3l-0.6-1.3L125.8-133.2z"
            />
            <path
              fill="#02A75B"
              stroke="#02A75B"
              strokeWidth={0.2788}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={22.3065}
              d="M148.2-141.8c-0.3-0.8-0.3-1.7-0.4-2.6l-0.1-2.1l0.3-1c0.4-1.1,0.8-1.6,1.2-2.4l1-1.2l1.4-1l1.7-0.9l1.9-0.4l1.7-0.1l2.5,0.5 l2.3,0.8l2.5,1.1l2.5,1.8l2.3,2.5c1.2,1.4,1.6,2.3,2.3,3.4c0.5,1.2,1.1,2.3,1,3.4c0.2,1.2,0.1,2.1,0.1,3.1 c-0.4,1.2-0.7,2.3-1.4,3.3c-0.3,0.4-0.8,1-1.1,1.3l-1,0.8l-0.9,0.6l-2.6,0.6c-1.5,0.2-2.9,0.2-4.5-0.2l-3.5-1.1l-3-1.7 c-1-0.5-1.5-1.2-2.1-1.9l-1.7-2l-1.2-1.8l-0.7-1.3L148.2-141.8z"
            />
            <path
              fill="#02A75B"
              stroke="#02A75B"
              strokeWidth={0.2788}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={22.3065}
              d="M172.1-147.6l-0.5-1.8l-0.1-1.1c0-0.7,0-1.3,0.2-1.9c0.1-0.9,0.6-1.9,1-2.8l1-1.1l1.5-1c0.6-0.3,1.3-0.6,1.9-0.8l1.8-0.1 l1.8-0.1l2.7,0.5c0.7,0.3,1,0.3,2.1,0.8c1.2,0.7,2.5,1.4,3.2,2.3c0.6,0.3,1.1,0.7,1.6,1.2l2.1,2c0.8,1.1,1.3,2.6,1.8,4 c0.3,1.2,0.6,2.3,0.6,3.5c-0.2,0.9-0.2,1.6-0.3,2.4l-0.9,1.8c-0.3,0.4-1.2,1.3-1.5,1.6l-0.9,0.6l-0.9,0.3l-1.7,0.5l-3.4,0.2 l-3.3-0.8l-2.6-1.4l-2.6-1.9l-2.7-2.8l-0.8-1.2l-0.5-1.3L172.1-147.6z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#FFFFFF"
            stroke="#FFFFFF"
            strokeWidth={0.2788}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={22.3065}
            strokeOpacity={0.9412}
            d=" M101.3-266l2-6.8l1.6-4.6l2.3-4.7l2.6-4.8l3.3-4.7l2.7-3.5l2-2.2l2.8-3.1l4.3,2.3l4.8,3.5l5.7,4.3l4.1,4.3l3.3,3.5l3.2,4.6 l2.8,3.9l2.3,4.1l1.6,3.9l1.4,4.3l0.7,4.2l0.2,4.7l-0.2,4.3l-1.5,4.3l-1.6,4.3l-2.5,4.1l-2.5,3.1l-3.2,3.8l-3.2,3.2l-6.2,4.4 c-3.1-20.1-5.6-40.3-9.9-60.4c1.5,19.2,4.3,40,6.8,60.4l-3.3-1.1l-2.8-0.6l-3.6-1.6l-4.7-2.5l-2.2-1.4l-2.1-1.6l-2.8-2.2 l-2.2-2.3l-2.2-2.6l-2.2-3.7l-2.2-6.3l-0.5-2.6l-0.6-5.1l0.5-4.4l0.6-4.3L101.3-266z"
          />

          <path
            fill="#FFFFFF"
            stroke="#FFFFFF"
            strokeWidth={0.2788}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={22.3065}
            strokeOpacity={0.9412}
            d="M130.1-203.3L82-208.1l10.6,2.2l9.9,1.6l7.6,1l10.6,1.7l8.6,1l-2.3,3.8l-2.8,3.8l-3.6,3.7l-3.3,2.7l-3.8,2.5l-4.3,1.5l-3.8,0.7 h-5.2l-4.6-0.9l-4.6-1.4l-6.4-3l-6.8-4.3l-5.1-4.2l-3.8-4.1l-3.9-4.9l-2.5-3.5l-2.1-3.2l3.1-2.7l4.9-4.3l3.3-2.5l3.3-2.1l6.5-3.2 l4.4-2.1l5.2-1.2l6.3-0.9l5.2-0.1l4.8,0.9l4.1,1l4.7,2.6l4.2,4.2l3.9,5.2l3.3,5.4l1.5,5.2L130.1-203.3z"
          />

          <path
            fill="#FFFFFF"
            stroke="#FFFFFF"
            strokeWidth={0.2788}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={22.3065}
            strokeOpacity={0.9412}
            d="M138.7-204.9l0.5-4.1v-3.5l0.7-3.1l1.2-4.6l2.5-5.2l3.2-4.7l5.1-4.7l4.9-2.6l3.8-1.5l5.4-0.7l4.3-0.5l5.5,0.1l5.1,0.4l5.7,1 l5.4,1.6l4.9,1.8l4.7,1.7l4.9,3.1l-0.7,3.3l-1.8,4.2l-2.8,6.8l-3.1,4.9l-2.6,3.5l-3.6,4.9l-3.2,3.7c-1.3,1.4-2.7,2.1-4.7,3.6 l-4.4,3l-3.9,1.8l-4.2,1.4l-4.3,0.5l-5.2-0.2l-4.8-1.5l-5.8-2.6l-5.1-3.3l-4.3-3.5l-2.2-2.8l19.5-7.6l9.7-3.8l12-4.8l9.6-4.1 l12.2-4.7l-11.8,4l-10.7,3.8l-8.7,3.1l-8.3,2.6l-9.5,3.7l-5.9,2L138.7-204.9z"
          />

          <path
            fill="#FFFFFF"
            stroke="#FFFFFF"
            strokeWidth={0.2788}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={22.3065}
            strokeOpacity={0.9412}
            d="M131.3-221.3l3,0.1l0.5,2.7l0.2,2.3l0.1,3.5l0.4,4.2l0.7,4.6l2.5-1.2l1,2.5l-3.3,1.5l0.4,3.3l0.1,2.6v2.7v2.8l-0.2,3.6l-0.4,3 l-0.4,3.1l-0.7,3.6l-1.2,4.2l-1.2,3.3l-1.6,3.2l-2.2,3.9l-1.7,3l-2.1-0.9l1.6-2.2l2-3.6l2.1-4.7l1.4-4.1l1-3l0.6-3l0.6-3 c0.4-2.8,0.6-6.1,0.6-9.9l-0.1-5.2l-0.6-2l0,0l-4.7-0.2l0.7-2.7l3.6,0.4l-0.9-4.2l-0.5-4.9l-0.1-3.1L131.3-221.3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SojaAllergensIcon;
