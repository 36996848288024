import { cloneElement, useRef, useState } from 'react';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop, DndProvider } from 'react-dnd';
import classNames from 'classnames';
// import translate from 'i18n/translate';
// import { InfoIcon, UploadCloudIcon } from 'components/UI/Icons';
import styles from './Dropbox.module.scss';
import DropboxPlaceholder from './DropboxPlaceholder/DropboxPlaceholder';
// import { LoadingSize } from 'components/Loading';
// import { colors } from 'data/data';
// import { useIntl } from 'react-intl';

const Dropzone = ({
  onFileUpload,
  loading = false,
  classes,
  multiple = false,
  acceptMIME = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'application/pdf',
  ],
  placeholder,
}) => {
  const [error, setError] = useState(undefined);
  const inputFileRef = useRef(null);
  // const { formatMessage } = useIntl();

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        console.log('DROP');
        setError(undefined);
        if (!multiple && item.files.length > 1) {
          setError('numOfFiles');
          return;
        }
        if (acceptMIME.length > 0) {
          const invalidMIME = item.files.filter(
            (file) => !acceptMIME.includes(file.type)
          );
          if (invalidMIME.length > 0) {
            setError('invalidMIME');
            return;
          }
        }
        const tooBigFiles = item.files.filter((file) => file.size > 10485760);
        if (tooBigFiles.length > 0) {
          setError('too_big_file');
          return;
        }

        onFileUpload?.(item.files);
      },
      canDrop(item, monitor) {
        return true;
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onFileUpload]
  );

  const isActive = canDrop && isOver;
  const placeholderComponent = placeholder || DropboxPlaceholder;

  const handleOpenFileDialog = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  };

  return (
    <div className={classNames(styles.container, classes?.container)}>
      <div
        ref={drop}
        className={classNames(styles.dropbox, classes?.container, {
          [styles.active]: isActive,
          [styles.loading]: loading,
          [styles.error]: !!error,
        })}
        onClick={handleOpenFileDialog}
      >
        {loading ? (
          <div
            className={classNames(styles.loadingContainer, classes?.loading)}
          >
            {/* <LoadingSize size={32} /> */}
            <span className={styles.loadingText}>
              {/* {translate('components.dropbox.loading')} */}
              loading
            </span>
          </div>
        ) : (
          cloneElement(placeholderComponent, { classes: classes?.placeholder })
        )}
      </div>
      <input
        data-cy="file-input"
        ref={inputFileRef}
        style={{ display: 'none' }}
        type="file"
        multiple
        onChange={(e) => {
          onFileUpload?.(Array.from(e.target.files)).then(() => {
            e.target.value = '';
          });
        }}
      />
      {error && (
        <div className={styles.errorMessage}>
          {/* <InfoIcon height={12} width={12} color={colors.red} /> */}
          {/* {formatMessage({ id: `components.dropbox.errors.${error}` })} */}
          {error}
        </div>
      )}
    </div>
  );
};

const Dropbox = (props) => (
  <DndProvider backend={HTML5Backend}>
    <Dropzone {...props} />
  </DndProvider>
);

export default Dropbox;
