import { createApi } from '@reduxjs/toolkit/query/react';
import { apiCallRTK } from '@utils/apiTools';

export const cartaApi = createApi({
  reducerPath: 'cartaApi',
  baseQuery: apiCallRTK,
  tagTypes: ['Carta'],
  keepUnusedDataFor: 120,
  endpoints: (builder) => ({
    getCarta: builder.query({
      query: (userId) => ({ url: `/carta/${userId}` }),
      providesTags: (result, error, userId) => [
        ...(result
          ? [
              {
                type: 'Carta',
                id: userId,
              },
            ]
          : []),
      ],
    }),
  }),
});

export const { useGetCartaQuery } = cartaApi;
