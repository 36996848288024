import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 24,
    marginBottom: 24,
  },
  tableItem: {
    alignItems: 'center',
    display: 'flex',
    gap: 12,
    width: '100%',
  },
  tableItemContent: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    display: 'flex',
    gap: 12,
    height: 56,
    padding: 16,
    width: '100%',

    '&.show': {
      borderBottomWidth: 1,
    },
  },
  tableGroup: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: 860,
  },
  tableExpand: {
    cursor: 'pointer',
    transformOrigin: '50% 50%',
    transition: 'all 0.2s ease-in-out',

    '&.expanded': {
      transform: 'rotate(90deg)',
    },
  },
  tableDishContainer: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    marginLeft: 28,
    maxHeight: 0,
    overflow: 'hidden',
    paddingLeft: 54,
    paddingRight: 26,
    transition: 'all .3s ease-in-out',
    width: 'calc(100% - 28px)',

    '&.show': {
      maxHeight: '100vw',
      paddingBottom: 26,
      paddingTop: 26,
    },
  },
  tableDishItem: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    display: 'flex',
    gap: 12,
    height: 56,
    padding: 16,
  },
  tableDragIcon: {
    alignItems: 'center',
    cursor: 'grab',
    display: 'flex',

    '&.active': {
      cursor: 'grabbing',
    },
  },
  categoryName: {
    flexGrow: 1,
    textAlign: 'left',
  },
  moreButton: {
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 12,
  },
});
