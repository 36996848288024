import Table from 'components/Table';
import useAPI from '@hooks/useAPI';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMenus } from '@store/slices/configSlice';
import { Button } from '@UI';

const ViewMenus = () => {
  const menus = useSelector((state) => state.config.menus);

  const [data, setData] = useState(menus);

  const { apiCall } = useAPI();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateMenu = () => navigate('create');

  const handleDragEnd = ({ source, destination }) => {
    if (!destination) return;
    reorderData(source.index, destination.index);
    apiCall(`/carta/menu/update/${data[source.index].id}`, 'PATCH', {
      order: destination.index,
    })
      .then(() => dispatch(getMenus()))
      .catch((err) => console.error('## ERROR on POST /carta/menu: ', err));
  };

  const columns = useMemo(() => {
    const NameCell = (props) => {
      return <span className="menu__table--cell">{props.cell.value}</span>;
    };

    return [
      {
        Header: 'Nombre',
        accessor: 'name',
        Cell: NameCell,
      },
    ];
  }, []);

  const reorderData = (startIndex, endIndex) => {
    const newData = [...data];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    setData(newData);
  };

  return (
    <>
      <div className="menus__header">
        <h3 className="menus__title">Menus</h3>
        <Button label={'+ Nuevo menu'} onClick={handleCreateMenu} />
      </div>
      <div className="menus__body">
        <Table
          columns={columns}
          data={data}
          reorderData={reorderData}
          handleDragEnd={handleDragEnd}
          classes={{
            table: 'menus__table',
            header: 'menus__table--header',
            body: 'menus__table--body',
            row: 'menus__table--row',
            data: 'menus__table--data',
          }}
          draggableRows
        />
      </div>
    </>
  );
};

export default ViewMenus;
