const MustardAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#C09328"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <path
      fillOpacity={0}
      stroke="#000000"
      strokeWidth={0.2742}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={21.9348}
      strokeOpacity={0}
      d="M94.6,47.7l40,40l51-51.5l-9.6-9.6l-12.6-9.6l-11-6.4l-7.6-3.7 L137.3,5L94.6,47.7z"
    />
    <g transform="translate(-189.27023,332.14547)">
      <g>
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth={0.2742}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={21.9348}
          d="M359.5-163.7c-0.6-1.5-0.9-3.2-1.8-4.5l-2.6-2.5l-4.4-2.9l-5.4-2.1l-7.6-2.1l-6.3-1.1l-8.4-0.6l-5.2,0.3l-6.3,2.3l-7.1,2.6l-5.5,1 l-8.8,0.2l-6.7-0.8l-7-1.1l-6.8-1l-6-0.5l-7.1-0.8l-7,0.3l-0.6-1.9l-1-4.4l0.5-3.2l0.8-1.1l4.9-3.1l4.5-4.2l2.1-3.1l0.2-4.2 l-0.3-4.1l-0.6-2.9l-1-2.6v-2.6l1.8-1.8l2.9-1.1l2.9-1.6l2.3-2.3l1.9-3.1l1-3.2l1.1-2.1l-0.3-1.8l-2.3-2.1h-2.1l-1.6,1l-1.3,2.8 l-1.1,3.1l-1.3,2.1l-3.1,1.5L256-222l-2.4,2.6l-1.6,3.2l-0.2,3.4l1,4.1l0.8,4.1l0.6,3.1l-0.6,1.8l-2.9,2.3l-2.4,1.9l-2.8,1.9 l-1.9,1.6l-1.6,2.9l-1,4.4l0.3,4.7l0.6,2.6l0.5,2.4l-3.7,1.5l-3.7,2.9l-1.9,2.9l-1,4.1l1.1,4.4l3.7,4.2l5.7,5.2l5.5,3.1l7,2.6 l6.7,1.5l10.6,1.1l11.4,0.6l13.2,1.1l11.7-0.3l11.4-1l8.9-1.6l8.3-2.3l8.6-3.7l6.5-3.7l4.7-4.4l2.3-3.6L359.5-163.7"
        />
        <path
          fill="#C09328"
          stroke="#C09328"
          strokeWidth={0.2742}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={21.9348}
          d="M326.9-150.5l4.4-0.8l3.3-0.9l4.3-1.8l2.9-1.6l2.2-1.3 l1.8-1.3l2-1.8l1.5-2.1l1.2-2.5l0.5-2l0.1-1.4l-0.1-0.6l0.9,1.1l0.6,1.6l0.2,1.3l-0.2,1.5l-0.3,1.1l-0.6,1.1l-0.9,1.3l-0.9,1.1 l-1.7,1.3l-1.7,1.4l-1.8,1.2l-2.4,1.6l-1.5,0.9l-1.7,0.7l-1.8,0.6l-1.8,0.6l-1.7,0.2l-2,0.1l-2-0.2l-1.3-0.1L326.9-150.5z"
        />
      </g>
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={0.2742}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={21.9348}
        d="M276.9-239.4l12.9-19.5l-10.1-9.8l6.9-6.9l28.6,28.5l-6.9,6.9 l-10.1-10l-18.7,13L276.9-239.4z"
      />
    </g>
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2742}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={21.9348}
      d="M94.8,47.5l40.4,40.4l53.1-53.9 c-3.7-5-8.8-9-14.4-12.8c-1.8-2-5.9-4.3-9.8-6.7C161.1,11.1,157,9,151,7.3c-1.5-1.8-7-2.5-12.8-4L94.8,47.5z"
    />
  </svg>
);

export default MustardAllergensIcon;
