import classNames from 'classnames';
import styles from './DropboxPlaceholder.module.scss';
import { UploadCloudIcon } from '@UI/Icons';

const DropboxPlaceholder = ({ classes }) => {
  return (
    <div className={classNames(styles.placeholder, classes)}>
      <UploadCloudIcon color="#CED4DA" />
      <span className={styles.instructions}>instructions</span>
      <span className={styles.maxSize}>max size</span>
    </div>
  );
};

export default DropboxPlaceholder;
