import { createTheme, adaptV4Theme } from '@mui/material/styles';

export default createTheme(
  adaptV4Theme({
    palette: {
      text: {
        primary: '#111111',
        secondary: '#000000',
        tertiary: '#FFFFFF',
      },
      action: {
        hover: '#ACD2CD',
        hoverOpacity: 0.08,
      },
      primary: {
        // main: '#00AEB7',
        // color: '#111111',
        main: '#06BDFF',
        dark: '#008fdc',
        light: '#b3e8ff',
        color: '#111111',
      },
      secondary: {
        // main: 'rgba(0,0,0,0.6)',
        main: '#ff6b37',
        dark: '#f14100',
        light: '#ffcbb9',
        color: '#111111',
      },
      tertiary: {
        main: '#cb2c51',
      },
      danger: {
        light: '#ff4081',
        main: '#f50057',
        dark: '#c51162',
        contrastText: '#FFFFFF',
      },
    },
    typography: {
      h1: {
        fontFamily: 'Roboto-Light',
        fontSize: 96,
        letterSpacing: -1.5,
        color: 'rgba(1,0,0,0.87)',
      },
      h2: {
        fontFamily: 'Roboto-Light',
        fontSize: 60,
        letterSpacing: -0.5,
        color: 'rgba(0,0,0,0.87)',
      },
      h3: {
        fontFamily: 'Roboto-Regular',
        fontSize: 48,
        letterSpacing: 0,
      },
      h4: {
        fontFamily: 'Roboto-Regular',
        fontSize: 34,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.87)',
      },
      h5: {
        fontFamily: 'Roboto-Regular',
        fontSize: 24,
        letterSpacing: 0,
        color: 'rgba(0,0,0,0.87)',
      },
      h6: {
        fontFamily: 'Roboto-Medium',
        fontSize: 20,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.87)',
      },
      body1: {
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.87)',
      },
      body2: {
        fontFamily: 'Roboto-Regular',
        fontSize: 14,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.87)',
      },
      subtitle1: {
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        letterSpacing: 0.15,
        color: 'rgba(0,0,0,0.87)',
      },
      subtitle2: {
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        letterSpacing: 0.1,
        color: 'rgba(0,0,0,0.87)',
      },
      button: {
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        letterSpacing: 1.25,
      },
      caption: {
        fontFamily: 'Roboto-Regular',
        fontSize: 12,
        letterSpacing: 0.4,
      },
      overline: {
        fontFamily: 'Roboto-Medium',
        fontSize: 12,
        letterSpacing: 2,
        color: 'rgba(0,0,0,0.87)',
      },
    },
    overrides: {
      MuiDialogTitle: {
        root: {
          fontSize: 30,
        },
      },
      MuiButton: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiIconButton: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiTabs: {
        indicator: {
          // backgroundColor: '#005457',
          backgroundColor: 'cyan',
        },
        flexContainer: {
          height: '65px',
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
            height: '64px',
          },
        },
      },
      MuiTab: {
        root: {
          fontFamily: 'Roboto-Regular',
          textTransform: 'capitalize',
          '&$selected': {
            fontFamily: 'Roboto-Medium',
            '& > .MuiTab-wrapper > *:first-child': {
              color: '#00AEB7',
              height: '20px',
              width: '20px',
            },
          },
        },
        labelIcon: {
          minHeight: 'auto',
          '& > span:first-child': {
            flexDirection: 'row',
          },
          '& > .MuiTab-wrapper > *:first-child': {
            margin: '0 0.5rem',
            height: '15px',
            width: '15px',
          },
        },
        textColorInherit: {
          opacity: 1,
        },
        fullWidth: {
          flexBasis: 'none',
        },
      },
    },
  })
);
