export const font = {
  text: {
    fontFamily: 'Raleway Light',
  },
  textRegular: {
    fontFamily: 'Raleway Regular',
    fontWeight: 100,
  },
  textBold: {
    fontFamily: 'Raleway Semi Bold',
    fontWeight: 100,
  },
  headline: {
    fontFamily: 'Raleway Extra Bold',
    fontWeight: 100,
  },
};

export const fontLight = () => ({
  fontFamily: 'Raleway Light',
});

export const fontRegular = () => ({
  fontFamily: 'Raleway Regular',
  fontWeight: 100,
});

export const fontBold = () => ({
  fontFamily: 'Raleway Semi Bold',
  fontWeight: 100,
});
