export const breakpoints = {
  xs: '576px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1400px',
  lb: '1600px',
  lxb: '1800px',
};

export const media = {
  iphone_5: {
    'max-width': '320px',
  },
  mobile: {
    'max-width': '767px',
  },
  tablet: {
    'min-width': '768px',
  },
  tablet_portrait: {
    'min-width': '768px',
    'max-width': '992px',
  },
  not_desktop: {
    'max-width': '991px',
  },
  desktop: {
    'min-width': '992px',
  },
  not_desktop_big: {
    'max-width': '1200px',
  },
  desktop_big: {
    'min-width': '1200px',
  },
  not_desktop_extra_big: {
    'max-width': '1400px',
  },
  desktop_1400: {
    'min-width': '1400px',
  },
  desktop_extra_big: {
    'min-width': '1400px',
  },
  desktop_1600: {
    'min-width': '1600px',
  },
  desktop_1800: {
    'min-width': '1800px',
  },
};

export const colors = {
  white: '#fff',
  black: '#000',
  //$red: #dc3545;
  red: '#e91f0c',
  orange: '#e95a0c',
  orange1: '#ff7800',
  blueColor: '#104e89',
  blueColor2: '#2775C1',
  blueColorHover: '#0a3257',
  green: '#7ed321',
  green_dark: '#429321',
  footer_background: '#000',
  footer_text_main: '#fff',
  //My colors
  primaryMain: '#06bdff',
  primaryDark: '#008fdc',
  primaryLight: '#b3e8ff',
  primaryDarker: '#1243ff',
  primaryLighter: '#12ffbd',
  secondaryMain: '#ff511f',
  terciaryMain: '#ffc41f',
};
