import { useState, useEffect } from 'react';
import useAPI from '@hooks/useAPI';
import { useForm } from 'react-hook-form';
import Button from '@UI/Button';
import { ArrowLeft, DocumentIcon } from '@UI/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getConfig } from '@store/slices/configSlice';
import { Checkbox } from '@fjpedrosa/malaga-ui/dist/cjs';

const CreateCategory = () => {
  const menus = useSelector((state) => state.config.menus);

  const [loadingApi, setLoadingApi] = useState(false);

  const dispatch = useDispatch();
  const { apiCall } = useAPI();
  const { categoryId } = useParams();
  const navigate = useNavigate();

  const category =
    useSelector((state) =>
      state.config.categories.find((category) => category.id === categoryId)
    ) || undefined;

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: category?.name || undefined,
      menus: category
        ? category.menus?.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
          }, {})
        : {},
    },
  });

  const name = watch('name');
  const formMenus = watch('menus');

  useEffect(() => {
    if (categoryId && !category) navigate('/dashboard/menu/create');
  }, [category, categoryId, navigate]);

  const goBack = () => navigate(-1);

  const onSubmit = (form) => {
    setLoadingApi(true);

    let url = '/category';
    if (category) url += `/update/${categoryId}`;

    apiCall(url, category ? 'PATCH' : 'POST', {
      name: form.name,
      menus: Object.keys(form.menus).filter((menuId) => form.menus[menuId]),
    })
      .then(() => dispatch(getConfig()))
      .catch((err) => console.error('## ERROR on POST /category: ', err))
      .finally(() => setLoadingApi(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="categories__header">
        <Button
          variant="icon"
          icon={<ArrowLeft height={28} width={28} />}
          onClick={goBack}
          classes={{ button: 'categories__header--back' }}
        />
        <div>
          <Button
            label="Guardar"
            type="submit"
            disabled={!name}
            loading={loadingApi}
          />
        </div>
      </div>
      <div className="categories__body">
        <input
          {...register('name', { required: true })}
          className="categories__body--input"
          type="text"
          placeholder={`Introduce el nombre de la categoría, p.e. "Desayunos"`}
        />
        {errors.name && (
          <div className="categories__body--input-error">
            Por favor, rellena este campo
          </div>
        )}
        <Button
          variant="text"
          classes={{ button: 'categories__body--notes' }}
          label={
            <div className="categories__body--notes-label">
              <DocumentIcon />
              Añadir nota
            </div>
          }
        />
        <div className="categories__menus">
          <span className="categories__menus--title">
            Incluir en los siguientes menús:
          </span>
          <div className="categories__menus--body">
            {menus.map(({ name, id }, index) => (
              <Checkbox
                key={id}
                name={`menus.${id}`}
                refs={register(`menus.${id}`).ref}
                title={name}
                hideErrors
                classes={{}}
                defaultChecked={!!formMenus[id] || false}
                onChange={(e) => setValue(`menus.${id}`, !!e.target.checked)}
              />
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateCategory;
