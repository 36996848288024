import Table from 'components/Table';
import useAPI from '@hooks/useAPI';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '@store/slices/configSlice';
import Button from '@UI/Button';

const ListCategories = () => {
  const { categories, menus } = useSelector((state) => state.config);

  const [data, setData] = useState(categories);

  const menusDictionary = useMemo(
    () =>
      menus.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      }, {}),
    [menus]
  );

  const { apiCall } = useAPI();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateCategory = () => navigate('create');

  const handleDragEnd = ({ source, destination }) => {
    if (!destination) return;
    reorderData(source.index, destination.index);
    apiCall(`/category/update/${data[source.index].id}`, 'PATCH', {
      order: destination.index,
    })
      .then(() => dispatch(getCategories()))
      .catch((err) => console.error('## ERROR on POST /carta/menu: ', err));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        Cell: (props) => (
          <Button
            label={props.value}
            variant="link"
            color="primary"
            onClick={() => navigate(`${props.row.original.id}`)}
          />
        ),
      },
      {
        Header: 'Menus',
        accessor: 'menus',
        Cell: (props) => (
          <div>
            {props.value?.map((value) => menusDictionary[value]).join(', ')}
          </div>
        ),
      },
      {
        Header: 'Platos',
        accessor: 'dishes',
        Cell: (props) => (
          <div>{props.value ? `${props.value.length} Platos` : '0 Platos'}</div>
        ),
      },
    ],
    [menusDictionary, navigate]
  );

  const reorderData = (startIndex, endIndex) => {
    const newData = [...data];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    setData(newData);
  };

  return (
    <>
      <div className="categories__header">
        <h3 className="categories__title">Categorias</h3>
        <Button label={'+ Nueva categoria'} onClick={handleCreateCategory} />
      </div>
      <div className="categories__body">
        <Table
          columns={columns}
          data={data}
          reorderData={reorderData}
          handleDragEnd={handleDragEnd}
          classes={{
            cell: 'categories__table--cell',
          }}
          draggableRows
        />
      </div>
    </>
  );
};

export default ListCategories;
