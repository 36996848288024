import DragHandleIcon from '@mui/icons-material/DragHandle';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStyles } from './styles';

const DishesTable = ({ category, handleDragEnd }) => {
  const styles = useStyles();
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={`${category.id}-dishes-body`}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {category.subRows.map((dish, index) => {
              return (
                <Draggable draggableId={dish.id} key={dish.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={styles.tableDishItem}
                    >
                      <span
                        {...provided.dragHandleProps}
                        {...snapshot.draggingOver}
                        aria-label="move"
                        role="img"
                        className={classNames(styles.tableDragIcon, {
                          active: snapshot.isDragging,
                        })}
                      >
                        <DragHandleIcon />
                      </span>
                      <span>{dish.name}</span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DishesTable;
