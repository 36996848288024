import { Outlet, useNavigate } from 'react-router-dom';
import SideBar from '../components/Sidebar';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import useAPI from '@hooks/useAPI';
import { initAuth } from '@store/slices/authSlice';
import { getConfig } from '@store/slices/configSlice';
import { Authenticator } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';

const PrivateRoutes = () => {
  const { route } = useAuthenticator((context) => [context.user]);
  const { usuario } = useSelector((state) => state.config);
  const { apiCall } = useAPI();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAppData = useCallback(() => {
    return new Promise((resolve, reject) => {
      dispatch(getConfig())
        .unwrap()
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          console.error('## ERROR: ', err);
          reject;
        });
    });
  }, [dispatch]);

  useEffect(() => {
    const onSignin = async () => {
      await getAppData();
      navigate('/dashboard');
    };

    Hub.listen('auth', (data) => {
      if (data?.payload?.event === 'signIn') {
        onSignin();
      }
    });
  }, [navigate, getAppData]);

  useEffect(() => {
    if (!usuario) {
      dispatch(initAuth())
        .unwrap()
        .then(getAppData)
        .catch((err) => {
          console.error('## ERROR: ', err);
        });
    }
  }, [dispatch, apiCall, usuario, getAppData]);

  if (route !== 'authenticated' || route === 'setup') {
    return <Authenticator />;
  }

  return (
    <div className="app">
      {!usuario ? (
        <CircularProgress />
      ) : (
        <>
          <SideBar />
          <div className="app__content">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default PrivateRoutes;
