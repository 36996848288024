import React, { useState } from 'react';
import classnames from 'classnames';
import { deepValue } from '../utils';
import ReactSelect, { components } from 'react-select';
import { useStyles } from './styles';
import DeleteIcon from '@UI/Icons/DeleteIcon';

const Select = ({
  title,
  id,
  name,
  placeholder = undefined,
  classes = {},
  options = [],
  defaultValue = null,
  onChange = undefined,
  isSearcheable = false,
  required,
  singleCol,
  hideErrors,
  maxMenuHeight = 140,
  refs,
  watch,
  onMenuOpen,
  onMenuClose,
  onFocus,
  onBlur,
  menuPlacement,
  errors,
  inputValue,
  disabled = false,
  chipSelector = false,
  ...others
}) => {
  const placeholderOpt = {
    label: placeholder || 'Seleccione una opción',
    value: 'undefined',
    isDisabled: true,
    isDefault: true,
  };
  const defaultSelectedOption =
    options.find((option) => option.value === defaultValue) || placeholderOpt;

  const [value, setValue] = useState(defaultSelectedOption || placeholderOpt);
  const styles = useStyles({ isSearcheable, classes });

  const itemErrors = name && deepValue(errors, name);

  const validations = {};
  if (required) {
    Object.assign(validations, {
      required: 'Por favor, elija una opción',
    });
  }

  const handleChange = (e) => {
    if (onChange) onChange(e.value);
    if (chipSelector) {
      setValue(placeholderOpt);
    } else {
      setValue(options.find((option) => option.value === e.value));
    }
  };

  const { Control, ValueContainer, Placeholder, Option, Menu, MenuPortal } =
    components;

  const CustomControl = ({ children, ...props }) => {
    return (
      <Control {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.label}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </Control>
    );
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const CustomOption = ({ children, ...props }) => {
    const innerPropsCopy = props.innerProps;
    delete innerPropsCopy.onMouseMove;
    delete innerPropsCopy.onMouseOver;
    const newProps = { ...props, innerProps: innerPropsCopy };
    newProps.innerProps.id = newProps.data.id;
    return (
      <Option {...newProps} className="custom__option">
        {children}
      </Option>
    );
  };

  const CustomMenu = ({ children, ...props }) => {
    return (
      <Menu {...props}>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </Menu>
    );
  };

  const CustomMenuPortal = ({ children, ...props }) => {
    return (
      <MenuPortal {...props}>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </MenuPortal>
    );
  };

  const CustomMultiValueRemove = ({ ...props }) => {
    return (
      <div {...props.innerProps}>
        <DeleteIcon width={18} height={18} />
      </div>
    );
  };

  return (
    <div className={classnames('select__container', classes.container)}>
      <ReactSelect
        ref={refs}
        id={id}
        name={name}
        isDisabled={disabled}
        maxMenuHeight={maxMenuHeight}
        onChange={handleChange}
        placeholder="Select..."
        value={
          inputValue
            ? options.find((option) => option.value === inputValue)
            : value
        }
        options={
          options.find((option) => option.value === defaultValue)
            ? options.map((option) => ({
                ...option,
                id: `${name}-option-${option.value}`,
              }))
            : [placeholderOpt].concat(
                options.map((option) => ({
                  ...option,
                  id: `${name}-option-${option.value}`,
                }))
              )
        }
        label={
          <>
            {title}
            {required && <span>*</span>}
          </>
        }
        components={{
          Control: CustomControl,
          ValueContainer: CustomValueContainer,
          Option: CustomOption,
          Menu: CustomMenu,
          MenuPortal: CustomMenuPortal,
          MultiValueRemove: CustomMultiValueRemove,
        }}
        styles={{
          ...styles,
        }}
        isSearchable={isSearcheable}
        classNamePrefix="custom__select"
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onFocus={onFocus}
        onBlur={onBlur}
        // isMulti={isMulti}
        {...others}
        menuPlacement={menuPlacement || 'auto'}
      />
      {itemErrors && (
        <span className={classnames(classes.error, 'ui__item--error')}>
          {errors[name].message}
        </span>
      )}
      {!hideErrors && !itemErrors && <div className="ui__item--error-empty" />}
    </div>
  );
};

export default Select;
