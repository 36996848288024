const Spinner = ({ size = 36 }) => {
  return (
    <div
      className={'spinner'}
      style={{ borderWidth: size / 5, width: size, height: size }}
    />
  );
};

export default Spinner;
