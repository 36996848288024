import { useState } from 'react';

export default function useDragnDrop() {
  const [expandedRows, setExpandedRows] = useState([]);

  const handleExpandClick = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows((s) => s.filter((el) => el !== index));
    } else {
      setExpandedRows((s) => [...s, index]);
    }
  };

  const expandAll = (numOfItems) => {
    setExpandedRows([...Array(numOfItems).keys()]);
  };

  const colapseAll = () => {
    setExpandedRows([]);
  };

  return {
    expandedRows,
    handleExpandClick,
    expandAll,
    colapseAll,
  };
}
