import useDragnDrop from '@hooks/useDragnDrop';
import { useSelector } from 'react-redux';
import { Button } from '@UI';
import CartaTable from './CartaTable';
import { useStyles } from './styles';

const Carta = () => {
  const styles = useStyles();
  const { carta } = useSelector((state) => state.carta);
  const { expandedRows, handleExpandClick, expandAll, colapseAll } =
    useDragnDrop();

  return (
    <div>
      {carta?.length && (
        <div className={styles.tableHeader}>
          <Button
            variant="text"
            label="Expandir todo"
            onClick={() => expandAll(carta?.length)}
          />
          <Button
            variant="text"
            label="Colapsar todo"
            onClick={() => colapseAll()}
          />
        </div>
      )}
      <CartaTable {...{ expandedRows, handleExpandClick }} />
    </div>
  );
};

export default Carta;
