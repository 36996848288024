import { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { deepValue } from '../utils';
import { useStyles } from './styles';

const TextInput = ({
  refs = undefined,
  label = '',
  name,
  placeholder = undefined,
  classes,
  defaultValue,
  required,
  hidden,
  readOnly = false,
  errors,
  onChange = undefined,
  hideErrors,
  onBlur = undefined,
  adornment,
  showAdornment,
}) => {
  const styles = useStyles({ classes });
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const itemErrors = name && deepValue(errors, name);

  const handleOnChange = (e) => {
    if (onChange) onChange(e);
    if (setValue) setValue(e.target.value);
  };

  return (
    <div
      className={classnames(styles.container, {
        hidden,
        [`${classes?.container}`]:
          classes?.container && typeof classes?.container === 'string',
      })}
    >
      <div
        className={classnames(styles.control, {
          [`${classes?.control}`]:
            classes?.control && typeof classes?.control === 'string',
        })}
        onFocus={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsFocused(true);
        }}
        onBlur={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsFocused(false);
          if (onBlur) onBlur();
        }}
      >
        <label
          className={classnames(styles.label, {
            active: !!value || isFocused || placeholder,
            [`${classes?.label}`]:
              classes?.label && typeof classes?.label === 'string',
          })}
        >
          {label}
          {required && <span>*</span>}
        </label>
        <input
          data-cy={name || null}
          ref={refs}
          id={name}
          name={name}
          placeholder={placeholder && !isFocused ? placeholder : null}
          type="text"
          defaultValue={defaultValue ?? ''}
          readOnly={readOnly}
          className={classnames(styles.input, {
            readonly: readOnly,
            [`${classes?.input}`]:
              classes?.input && typeof classes?.input === 'string',
          })}
          onChange={handleOnChange}
        />
        {showAdornment && <div className={styles.adornment}>{adornment}</div>}
      </div>
      {itemErrors && (
        <span
          className={classnames(styles.error, {
            [`${classes?.error}`]:
              classes?.error && typeof classes?.error === 'string',
          })}
        >
          {itemErrors.message}
        </span>
      )}
      {!hideErrors && !itemErrors && <div className={styles.emptyError} />}
    </div>
  );
};

TextInput.propTypes = {
  /** Reference to this input component.*/
  refs: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /** Label of the number input. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Name of this element to reference it on react-hook-form.*/
  name: PropTypes.string,
  /** Text shown when the input is empty. */
  placeholder: PropTypes.string,
  /** Object with classes to extend and/or override.*/
  classes: PropTypes.object,
  /** Default name of the file.*/
  defaultValue: PropTypes.node,
  /** When true it is mandatory to this component to have a value.*/
  required: PropTypes.bool,
  /** When true the component exists but is not shown.*/
  hidden: PropTypes.bool,
  /** If true this component is in read-only mode.*/
  readOnly: PropTypes.bool,
  /** This prop indicates that the element is the only one in a column.*/
  singleCol: PropTypes.bool,
  /** Error object from react-hook-form.**/
  errors: PropTypes.object,
  /** Method to execute when the value changes. This function returns (e) => {}.*/
  onChange: PropTypes.func,
  /** In case that you don't want to show errors or dont want to keep the space at the bottom where errors are shown.*/
  hideErrors: PropTypes.bool,
  /** Function executed when input is unfocused.**/
  onBlur: PropTypes.func,
  /** Element shown on the right side of the input.**/
  adornment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** When true the adornment is shown.**/
  showAdornment: PropTypes.bool,
};

export default TextInput;
