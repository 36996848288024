import Banner from 'components/Banner';
import { useNavigate } from 'react-router-dom';
import { Button } from '@fjpedrosa/malaga-ui';
import { useStyles } from './styles';
import MenuSelector from './MenuSelector';
import Carta from 'components/Carta';

const Summary = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <div className={styles.summary}>
      <Banner>
        <h4>Las fotos de los platos pueden incrementar sus ventas</h4>
        <p>
          Estas ayudan a los clientes a elegir que pedir. Para añadir fotos,
          vaya a platos y elija uno al que añadir una foto.
        </p>
        <Button
          color="orange"
          label="+ Añadir foto"
          onClick={() => navigate('/dashboard/platos')}
        />
      </Banner>

      <MenuSelector />

      <div className={styles.body}>
        <Carta />
      </div>
    </div>
  );
};

export default Summary;
