import { colors } from 'assets/scss/variables/variables';
import { createUseStyles } from 'react-jss';
import { font } from 'assets/scss/helpers/fonts';

export const useStyles = createUseStyles({
  timetable: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    ...font.headline,
    fontSize: 24,
    fontWeight: 'bold',
    margin: {
      top: 24,
      left: 0,
      bottom: 12,
      right: 0,
    },
    textAlign: 'left',
  },
  timeSelect: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
  },
  selector: {
    border: '1px solid #bbb',
    maxWidth: 900,
    padding: 24,
  },
  days: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  day: {
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'bold',
    height: 36,
    justifyContent: 'center',
    width: 88,

    '&.selected': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  hours: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    width: '100%',
  },
  hour: {
    textAlign: 'left',
    width: 77,
  },
  timeCell: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  time: {
    backgroundColor: '#f5f5f5',
    borderLeft: '1px solid #ccc',
    height: 46,
    width: 80,

    '&.last-item': {
      borderRight: '1px solid #ccc',
    },
  },
  selectedTime: {
    backgroundColor: colors.primaryMain,
    height: 34,
    position: 'absolute',
    top: 6,
  },
  selectContainer: {
    marginTop: 24,
    width: 180,
  },
  checkboxContainer: {
    marginLeft: 36,
  },
});
