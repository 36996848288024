export const useStyles = ({ isSearcheable = false, classes = {} } = {}) => ({
  container: (provided) => ({
    ...provided,
    position: 'relative',
    width: '100%',
    height: '100%',

    ...classes.container,
  }),
  control: (provided) => ({
    ...provided,
    position: 'relative',
    width: '100%',
    height: 46,
    cursor: isSearcheable ? 'pointer' : 'text',
    appearance: 'none',
    background: 'white',
    border: '1px solid #ccc',
    borderRadius: 3,
    boxShadow: 'none',
    '&:hover': { borderColor: '#000' },
    ...classes.control,
  }),
  valueContainer: (provided) => ({
    ...provided,
    position: 'relative',
    boxSizing: 'border-box',
    color: '#000',
    fontSize: 16,
    outline: 'none',
    transition: 'all 0.2s ease-in-out',
    width: '100%',
    height: '100%',
    caret: isSearcheable ? 'block' : 'none',
    cursor: isSearcheable ? 'text' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    overflow: 'visible',
    ...classes.valueContainer,
  }),
  placeholder: () => ({
    fontFamily: 'Raleway Extra Bold',
    fontWeight: 100,
    color: '#999',
    fontSize: 10,
    paddingLeft: 9,
    top: 3,
    cursor: 'text',
    position: 'absolute',
    transition: 'all 0.2s',

    span: {
      color: '#d65b53',
      display: 'inline-block',
      paddingLeft: 5,
    },
    ...classes.placeholder,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    ...classes.indicatorsContainer,
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    ...classes.indicatorContainer,
  }),
  singleValue: (provided, state) => {
    return {
      ...provided,
      // position: 'absolute',
      width: '100%',
      maxWidth: '100%',
      padding: '19px 0 0 10px',
      lineHeight: '19px',
      height: '100%',
      margin: 0,
      left: 0,
      fontSize: 14,
      display: 'flex',
      color: state.data?.isDefault ? '#888' : '#000',
      ...(typeof classes.singleValue === 'function'
        ? classes.singleValue(state)
        : classes.singleValue),
    };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    color: '#000',
    transition: 'all 0.4s',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    ...classes.dropdownIndicator,
  }),
  input: (provided, state) => ({
    ...provided,
    position: 'absolute',
    height: '100%',
    width: 'auto',
    left: 9,
    top: 5,
    padding: 0,
    margin: 0,
    fontSize: 14,
    lineHeight: '14px',

    '& input': {
      fontFamily: 'Raleway Light',
      border: '1px solid blue',
      // position: 'absolute',
      // top: 0,
      paddingTop: 10,
      '&::focus': {
        backgroundColor: 'white',
      },
    },
    ...classes.input,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10,
    marginTop: state.getValue()[0]?.isDefault ? 4 : 0,
    ...classes.menu,
  }),
  menuPortal: ({ ...provided }) => ({
    ...provided,
    position: 'absolute',
    zIndex: 100,
  }),
  option: (provider, state) => ({
    ...provider,
    display: state.data?.isDefault ? 'none' : 'block',
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: '14px',
    backgroundColor: state.isSelected ? '#2793FF' : 'transparent',
    ...classes.option,

    color: state.isSelected ? 'white' : 'black',
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#06bdff',
    borderRadius: 16,
    paddingLeft: 8,
    paddingRight: 8,
    ...classes.multiValue,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontFamily: 'Raleway Semi Bold',
    color: '#fff',
    ...classes.multiValueLabel,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      transform: 'scale(1.1)',
      svg: {
        color: 'rgba(0, 0, 0, 0.4)',
      },
    },
    padding: 0,
    ...classes.multiValueRemove,
  }),
});

export const colouredStyles = ({ bgColor, classes }) => ({
  item: classes.item,
  control: {
    border: 'none',
  },
  singleValue: {
    paddingTop: 3.4,
    paddingLeft: 0,
    background: bgColor,
    borderRadius: 5,
    justifyContent: 'center',
    div: {
      background: 'transparent !important',
    },
  },
  menu: {
    marginTop: 6,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 10,
  },
  container: {
    height: 'auto',
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 5,
  },
  option: {
    paddingTop: 4.5,
    paddingBottom: 4.5,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#deebff',
    },
  },
  placeholder: {
    fontFamily: 'Raleway Light',
  },
  indicatorsContainer: {
    display: 'none',
  },
});
