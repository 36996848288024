import { Auth } from 'aws-amplify';
import { API_URL } from './constants';

function parseJSON(response) {
  return new Promise((resolve) =>
    response.json().then((body) =>
      resolve({
        status: response.status,
        ok: response.ok,
        body,
      })
    )
  );
}

export const apiCallRTK = async ({
  url = '',
  method,
  body,
  headers,
  signal,
}) => {
  const token = await Auth.currentSession().then((data) =>
    data.getIdToken().getJwtToken()
  );

  const isBodyFormData = body instanceof FormData;
  const sanitizedBody = isBodyFormData ? body : JSON.stringify(body);
  const sanitizedHeader = {
    Authorization: `Bearer ${token}`,
    'Content-Type': isBodyFormData ? 'multipart/form-data' : 'application/json',
    'x-api-key': import.meta.env.VITE_API_KEY,
    ...headers,
  };

  return new Promise((resolve) => {
    fetch(`${API_URL}${url}`, {
      method,
      body: sanitizedBody,
      signal,
      headers: sanitizedHeader,
      contentType: !isBodyFormData,
    })
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          resolve({ data: response.body });
        } else {
          resolve({ error: response.body || {} });
        }
      })
      .catch((error) => {
        console.log('ERROR: ', error);
        resolve({ error });
      });
  });
};

export const apiCallFormData = async ({
  url = '',
  method = 'POST',
  body,
  token,
}) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      processData: false,
      contentType: false,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          resolve(response.body);
        } else {
          reject(response.body);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const apiCall = async ({ url, method = 'GET', body, signal, token }) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method,
      body: JSON.stringify(body),
      signal,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          resolve(response.body);
        } else {
          reject(response);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
