import PropTypes from 'prop-types';

const DocumentIcon = ({ width = 24, height = 24, classes, color = '#000' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path
        d="m 15.7055 8.41 l -3.7167 -4.83 c -0.2847 -0.37 -0.6772 -0.58 -1.085 -0.58 H 3.8475 c -0.8464 0 -1.539 0.9 -1.539 2 v 14 c 0 1.1 0.6925 2 1.539 2 h 10.773 c 0.8464 0 1.539 -0.9 1.539 -2 V 9.83 c 0 -0.53 -0.1616 -1.04 -0.454 -1.42 z M 5.3865 7 h 5.3865 v 2 H 5.3865 V 7 z m 7.695 10 H 5.3865 v -2 h 7.695 v 2 z m 0 -4 H 5.3865 v -2 h 7.695 v 2 z"
        stroke={color}
        fill={color}
      />
    </svg>
  );
};

DocumentIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.string,
  color: PropTypes.string,
};

export default DocumentIcon;
