const CrustaceanAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#1CB6F1"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M145.1,124.8l-1.3,11l-1.4,5c-0.5,2-1.3,3.8-2.2,5.6c-0.9,1.4-2.6,2.5-3.9,3.8l-6.9,5l-5.3,3.4l-4,2l-8.2,0.1l-4.6-0.2 c-3.9,0.2-8.1,0-12.2,0c-4.4-1.8-8.5-4.9-12.8-7.4l-7.5-6.2l-2.3-5.1l-1.9-8.5l-1.1-11.6l0.9-4.8l1.7-2.9l1.7-2l2-4.5l3.7-9.1l2-3 l3.2-2.7l4.1-2l1.9-1.4l1-1.3l0.5-1l0.5-2l0.7-1.6l2.1-0.5l2.2,0.8l2.3,1.6l2.1,0.5l2.3-0.3l1.8,0c0.4,0.1,0.3,0.3,0.4,0.4l0.7,0.1 l0.3,0.2l0.2-0.4l0.6,0l0.4-0.3l1.1,0l1.5,0.2l1.2,0l1.2,0l1.1-0.2l0.9-0.4l1.5-1l0.6-0.2l0.8-0.3l1.1-0.3l1.3,0l1,0.7l0.5,1.2 l0.3,2.4l0.7,0.8l1.4,1.6l3.2,1.6l2,0.9l2,1.4l1.3,1.3l1.5,2.5l0.5,1l1.4,2.7l1.7,4.1l0.6,1.5l1.8,4l2,2.9l1.5,2.6l0.3,0.8l0.6,1.5 L145.1,124.8z"
    />
    <g transform="translate(178.21525,347.36256)">
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-108.4-233.7l1.9-2.4h0.2l0.8-1l1.9-5.2l1.6-4.7l-8.3-6.3l1.5-2.3l1-3.5l0-5.8l-1-5.5l3.9-2.3l5.7-4.4l6.1-6.6l3.4-6.6l1.3-6.7 c-0.2-1.7-0.1-2.9-0.7-5.4l-0.6-2.6l-1.9,3.5l-2.7,4.4l-3.4,3.9l-3,3.3l-3.6,2.9l-3.4,2.3l-2.4,1.3l0.1-4.8l1.8-10l3.1-8l3-7.2 l-3.4,2.5l-5,3.2l-2.5,1.9l-3.6,3.6l-3,3.9l-2.8,3.9l-2.8,6l-2.3,8l-0.7,5.4l-0.2,4.3l0.7,6.2l1,3.9l1.3,4.1l1.4,2.4l1.6,1.5 l1.4,1.1l1.7,2.9l1,2l2.4,4.6l2.5,3.2l1.7,2l1.6,1.7l1,1C-108.6-234.1-108.7-234.3-108.4-233.7L-108.4-233.7z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-108.5-233.3l-3.9-1.3l-3.1-1l-3.1-0.8l-1.7-0.4l-1.4-0.3 l-1,0.2l-0.8,0.3l-0.6,0.5l-1.1,1l-0.6,0.4l-0.4,0.7l0.1,0.8l0.2,1l0.8,1.2l1.1,1l1.1,0.9l1.7,1.3l2.1,1.1l1.8,0.9l1.9,0.9l3.9,1.6 l-3.7,0.5l-4,0.6l-3.4,0.4l-2.3,0.4l-1.2,0.6l-0.9,0.9l-0.5,1.3l-0.4,1.3l0,1.2l0.7,1.1l1.3,0.8l1.6,0.5l1.9,0.5l1.8,0.2l2.3,0.1 l2.6,0l2.2-0.2l2.4-0.2l0.5-0.1l-1,0.3l-1.5,0.5l-2.9,1.3l-2.7,1.8l-1.4,1.1l-1.4,1.3l-1.5,1.7l-1.1,1.7l-0.6,1.4l-0.2,1.7l0.6,1.3 l0.8,0.9l0.7,0.5l0.9,0.5l1,0.3l1-0.2l1.4-0.5l1.2-0.6l1.5-0.9l1.7-1.1l1.2-0.7l1.4-0.6l2-0.8l1.5-0.4l0.7-0.2l-1,0.6l-1,0.8 l-1.7,1.5l-0.7,1l-1,1.6l-0.9,1.8l-0.6,1.5l-0.2,0.9l0.1,1.1l0.3,1.4l1,0.9l0.8,0.3l0.4,0l0.8,0.4l0.9,0.3l1.3-0.4l1.1-0.8l1.5-1.5 l1.3-0.9l2.7-1.7l1.8-0.8l0.4-0.2l-1-0.8l-1.5-1.2l-1-1.2l-1.1-1.4l-0.8-1.3l-1.6-3.7l-0.7-2.4l-1.1-4.6l-0.2-1.5l-1-6.8l-0.2-3.3 l0.1-4.3l0.2-1l0.2-0.3l0-0.3l0.1-1.1l0.7-1.5l0.5-1L-108.5-233.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-135.1-243.4l0.4,0.9l0.9,1.5l0.9,1.6l0.9,1.1l0.9,1.2l0.7,0.7l0.5,0.4l0.8,0.4l0.7,0.1l0.9,0l0.9-0.4l0.9-0.9l0.5-1.2l0.1-1.1 l-0.5-1.8l-0.9-1l-1.7-2.2l-2-2.8l-1.3-2l-0.8-1.2l-0.9-0.9l-0.7-0.4l-1-0.2l-1,0.4l-0.7,0.7l-0.2,1l0.2,1.1l0.3,1.4l0.5,1.5 l0.3,0.9l0.4,0.8L-135.1-243.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-138.9-263.8l-0.1,1l-0.2,1l0,1.8l0,1.5l0.1,1.2l0.1,1.5 l0.1,0.9l0.1,0.6l0.3,0.5l0.4,0.5l0.4,0.2l0.6,0.3l0.7-0.1l0.7-0.3l0.5-0.5l0.3-0.5l0.2-0.5l0-0.5l0-0.4l-0.2-0.3l-0.4-1.2l-0.5-2 l-0.3-1.3l-0.1-0.7l-0.5-1.6l-0.5-1.8l-0.4-1.1l-0.2-0.3l-0.3-0.2l-0.4,0.4l-0.3,0.8l-0.1,0.6L-138.9-263.8z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-146.7-243.9l-0.3,0.3l-0.2,1l-0.1,0.9l-0.1,1.3l-0.1,1.5 l0,1l0,1l0.2,1.3l0.3,1.3l0.3,1.3l0.3,0.4l0.2,0.6l0.3,0.5l0.5,0.3l0.7,0.1l0.6-0.2l0.2-0.2l0.8-0.5l0.3-0.5l0.1-0.3l0.2-0.5 l-0.2-0.9l-0.4-0.6l-1-2.1l-0.7-1.9l-0.7-2.6l-0.5-1.5l-0.3-0.9l-0.2-0.3L-146.7-243.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2581}
        strokeMiterlimit={22.3065}
        d="M-139.2-221.9l-1-0.9l-1.1-0.8l-1.1-0.9l-0.5-0.8l-0.5-1l-0.4-0.9l-0.1-0.7l0-1l0.5-0.5l0.5-0.3c0.4-0.5,0.7-0.4,1.1-0.4l1.3,0.5 l1.9,1.4l1.9,1.4l2,1.2l2.5,1.4l1.8,1l0.4,0.1l0.8,0.8l0.3,0.3l0.3,0.6l0,0.5l-0.2,0.7l-0.3,0.6l-0.4,0.6l-0.3,0.3l-0.6,0.3 l-0.7,0.2l-0.9,0l-0.7-0.1l-1.5-0.5l-1.6-0.9l-1.6-0.9l-1.1-0.6L-139.2-221.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-125-188.3l1.9-4.2 c0.3-0.5,0.8-0.8,0.6-2c-0.3-0.9-0.3-1-0.6-1.6c-0.5-0.9-0.9-0.9-1.4-1.3c-0.9-0.6-1.5-0.3-2.1-0.2c-1.3,0.7-1.6,1.3-2.2,1.8 l-2.3,5.7l-0.8,3.4l-0.3,3.8l0.7,2.2l1.5,1.1l1.4-0.3l0.8-1.1l1.7-4.5L-125-188.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-130.8-177.2l0.6-0.8l0.5-0.2l0.6-0.1l0.9,0.1l0.7,0.3l0.3,0.8l0.1,0.5l0.3,0.9l0.2,0.9l0.4,1.5l0.6,1.6l0.8,1.9l0.5,1.5l0.6,1.1 l0.3,0.6v0.5l-0.6,0.1l-1-0.5l-0.9-0.6l-1.4-1.2l-1.1-1.5l-1.4-2.2l-0.6-1.4l-0.6-1.7l-0.2-1.2L-130.8-177.2z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-105-172.3l0.4-0.4l0.5-0.4l0.6-0.4l0.9,0l1.8,0.5l1.8,0.6l1.2,0.4l1.7,0.5l1.3,0.3l1.5,0.4l1.2,0.4l0.6,0.6l-0.2,0.3l-0.6,0.3 l-0.7,0.2l-0.7,0.1l-1,0.3l-1.3,0.1l-0.9,0h-1.4l-1.2-0.1l-1.5-0.1l-1.2-0.2l-1.5-0.3l-0.9-0.6l-0.6-0.8v-1L-105-172.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M-109.9-180.7l-0.1-1l0-1v-0.7l0.1-0.7v-0.5l0-0.4l-0.2-0.8 l-0.6-0.9l-0.5-0.6l-0.9-0.4l-0.6-0.4h-0.6l-0.7,0.1l-0.6,0.4l-0.9,0.6l-0.7,1l-0.2,0.7l-0.2,1.3l0,1.4l0.2,1.7l0.3,1.5l0.4,1.4 l0.7,1.6l1,1.4l1.3,1.7l1.5,0.8l1.2,0.7l1.4,0.4l1,0.1l0.8-0.2l0.4-0.5l0.3-0.6l0.2-0.7l-0.2-1.1l-0.7-1l-1.2-1.5l-0.9-1.2 l-0.5-0.9l-0.3-0.8L-109.9-180.7z"
      />
    </g>
    <g transform="matrix(-1,0,0,1,308.89974,347.52368)">
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M162.6-233l1.9-2.4h0.2l0.8-1l1.9-5.2l1.6-4.7l-8.3-6.3l1.5-2.3l1-3.5l0-5.8l-1-5.5l3.9-2.3l5.7-4.4l6.1-6.6l3.4-6.6l1.3-6.7 c-0.2-1.7-0.1-2.9-0.7-5.4l-0.6-2.6l-1.9,3.5l-2.7,4.4l-3.4,3.9l-3,3.3l-3.6,2.9l-3.4,2.3l-2.4,1.3l0.1-4.8l1.8-10l3.1-8l3-7.2 l-3.4,2.5l-5,3.2l-2.5,1.9l-3.6,3.6l-3,3.9l-2.8,3.9l-2.8,6l-2.3,8l-0.7,5.4l-0.2,4.3l0.7,6.2l1,3.9l1.3,4.1l1.4,2.4l1.6,1.5 l1.4,1.1l1.7,2.9l1,2l2.4,4.6l2.5,3.2l1.7,2l1.6,1.7l1,1C162.4-233.3,162.3-233.6,162.6-233L162.6-233z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M162.5-232.5l-3.9-1.3l-3.1-1l-3.1-0.8l-1.7-0.4l-1.4-0.3 l-1,0.2l-0.8,0.3l-0.6,0.5l-1.1,1l-0.6,0.4l-0.4,0.7l0.1,0.8l0.2,1l0.8,1.2l1.1,1l1.1,0.9l1.7,1.3l2.1,1.1l1.8,0.9l1.9,0.9l3.9,1.6 l-3.7,0.5l-4,0.6l-3.4,0.4l-2.3,0.4l-1.2,0.6l-0.9,0.9l-0.5,1.3l-0.4,1.3l0,1.2l0.7,1.1l1.3,0.8l1.6,0.5l1.9,0.5l1.8,0.2l2.3,0.1 l2.6,0l2.2-0.2l2.4-0.2l0.5-0.1l-1,0.3l-1.5,0.5l-2.9,1.3l-2.7,1.8l-1.4,1.1l-1.4,1.3l-1.5,1.7l-1.1,1.7l-0.6,1.4l-0.2,1.7l0.6,1.3 l0.8,0.9l0.7,0.5l0.9,0.5l1,0.3l1-0.2l1.4-0.5l1.2-0.6l1.5-0.9l1.7-1.1l1.2-0.7l1.4-0.6l2-0.8l1.5-0.4l0.7-0.2l-1,0.6l-1,0.8 l-1.7,1.5l-0.7,1l-1,1.6l-0.9,1.8l-0.6,1.5l-0.2,0.9l0.1,1.1l0.3,1.4l1,0.9l0.8,0.3l0.4,0l0.8,0.4l0.9,0.3l1.3-0.4l1.1-0.8l1.5-1.5 l1.3-0.9l2.7-1.7l1.8-0.8l0.4-0.2l-1-0.8l-1.5-1.2l-1-1.2l-1.1-1.4l-0.8-1.3l-1.6-3.7l-0.7-2.4l-1.1-4.6l-0.2-1.5l-1-6.8l-0.2-3.3 l0.1-4.3l0.2-1l0.2-0.3l0-0.3l0.1-1.1l0.7-1.5l0.5-1L162.5-232.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M135.9-242.6l0.4,0.9l0.9,1.5l0.9,1.6l0.9,1.1l0.9,1.2l0.7,0.7l0.5,0.4l0.8,0.4l0.7,0.1l0.9,0l0.9-0.4l0.9-0.9l0.5-1.2l0.1-1.1 l-0.5-1.8l-0.9-1l-1.7-2.2l-2-2.8l-1.3-2l-0.8-1.2l-0.9-0.9l-0.7-0.4l-1-0.2l-1,0.4l-0.7,0.7l-0.2,1l0.2,1.1l0.3,1.4l0.5,1.5 l0.3,0.9l0.4,0.8L135.9-242.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M132.1-263.1l-0.1,1l-0.2,1l0,1.8l0,1.5l0.1,1.2l0.1,1.5 l0.1,0.9l0.1,0.6l0.3,0.5l0.4,0.5l0.4,0.2l0.6,0.3l0.7-0.1l0.7-0.3l0.5-0.5l0.3-0.5l0.2-0.5l0-0.5l0-0.4l-0.2-0.3l-0.4-1.2l-0.5-2 l-0.3-1.3l-0.1-0.7l-0.5-1.6l-0.5-1.8l-0.4-1.1l-0.2-0.3l-0.3-0.2l-0.4,0.4l-0.3,0.8l-0.1,0.6L132.1-263.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M124.3-243.1l-0.3,0.3l-0.2,1l-0.1,0.9l-0.1,1.3l-0.1,1.5 l0,1l0,1l0.2,1.3l0.3,1.3l0.3,1.3l0.3,0.4l0.2,0.6l0.3,0.5l0.5,0.3l0.7,0.1l0.6-0.2l0.2-0.2l0.8-0.5l0.3-0.5l0.1-0.3l0.2-0.5 l-0.2-0.9l-0.4-0.6l-1-2.1l-0.7-1.9l-0.7-2.6l-0.5-1.5l-0.3-0.9l-0.2-0.3L124.3-243.1z"
      />

      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2581}
        strokeMiterlimit={22.3065}
        d="M131.8-221.2l-1-0.9l-1.1-0.8l-1.1-0.9l-0.5-0.8l-0.5-1l-0.4-0.9l-0.1-0.7l0-1l0.5-0.5l0.5-0.3c0.4-0.5,0.7-0.4,1.1-0.4l1.3,0.5 l1.9,1.4l1.9,1.4l2,1.2l2.5,1.4l1.8,1l0.4,0.1l0.8,0.8l0.3,0.3l0.3,0.6l0,0.5l-0.2,0.7l-0.3,0.6l-0.4,0.6l-0.3,0.3l-0.6,0.3 l-0.7,0.2l-0.9,0l-0.7-0.1l-1.5-0.5l-1.6-0.9l-1.6-0.9l-1.1-0.6L131.8-221.2z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M146-187.6l1.9-4.2 c0.3-0.5,0.8-0.8,0.6-2c-0.3-0.9-0.3-1-0.6-1.6c-0.5-0.9-0.9-0.9-1.4-1.3c-0.9-0.6-1.5-0.3-2.1-0.2c-1.3,0.7-1.6,1.3-2.2,1.8 l-2.3,5.7l-0.8,3.4l-0.3,3.8l0.7,2.2l1.5,1.1l1.4-0.3l0.8-1.1l1.7-4.5L146-187.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M140.2-176.4l0.6-0.8l0.5-0.2l0.6-0.1l0.9,0.1l0.7,0.3l0.3,0.8l0.1,0.5l0.3,0.9l0.2,0.9l0.4,1.5l0.6,1.6l0.8,1.9l0.5,1.5l0.6,1.1 l0.3,0.6v0.5l-0.6,0.1l-1-0.5l-0.9-0.6l-1.4-1.2l-1.1-1.5l-1.4-2.2l-0.6-1.4l-0.6-1.7l-0.2-1.2L140.2-176.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M166-171.5l0.4-0.4l0.5-0.4l0.6-0.4l0.9,0l1.8,0.5l1.8,0.6l1.2,0.4l1.7,0.5l1.3,0.3l1.5,0.4l1.2,0.4l0.6,0.6l-0.2,0.3l-0.6,0.3 l-0.7,0.2l-0.7,0.1l-1,0.3l-1.3,0.1l-0.9,0h-1.4l-1.2-0.1l-1.5-0.1l-1.2-0.2l-1.5-0.3l-0.9-0.6l-0.6-0.8v-1L166-171.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M161.1-179.9l-0.1-1l0-1v-0.7l0.1-0.7v-0.5l0-0.4l-0.2-0.8 l-0.6-0.9l-0.5-0.6l-0.9-0.4l-0.6-0.4h-0.6l-0.7,0.1l-0.6,0.4l-0.9,0.6l-0.7,1l-0.2,0.7l-0.2,1.3l0,1.4l0.2,1.7l0.3,1.5l0.4,1.4 l0.7,1.6l1,1.4l1.3,1.7l1.5,0.8l1.2,0.7l1.4,0.4l1,0.1l0.8-0.2l0.4-0.5l0.3-0.6l0.2-0.7l-0.2-1.1l-0.7-1l-1.2-1.5l-0.9-1.2 l-0.5-0.9l-0.3-0.8L161.1-179.9z"
      />
    </g>
  </svg>
);

export default CrustaceanAllergensIcon;
