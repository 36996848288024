import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserdata } from '@store/slices/configSlice';
import useAPI from '@hooks/useAPI';
import { useStyles } from './styles';
import { Button, TextInput } from '@fjpedrosa/malaga-ui';
import { useForm } from 'react-hook-form';

const ProfilePage = () => {
  const styles = useStyles();
  const { usuario } = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { apiCall } = useAPI();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      phone: usuario?.phone,
      address: usuario?.address,
      email: usuario?.email,
      city: usuario?.city,
      cif: usuario?.cif,
      province: usuario?.province,
      postalCode: usuario?.postalCode,
      businessName: usuario?.businessName,
      businessType: usuario?.businessType,
      manager: usuario?.manager,
    },
  });

  // const onChangeFile = async (event, source) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   console.log('image is: ', source);
  //   let file = await readFileAsync(event.target.files[0]).catch((err) => {
  //     console.error('ERROR: ', err);
  //     return;
  //   });
  //   console.log('PUT /user');
  //   //Here directly upload with a call to API
  //   apiCall('/user', 'PUT', {
  //     ...usuario,
  //     cover_img: source === 'cover' ? file : usuario.cover_img,
  //     profile_img: source === 'profile' ? file : usuario.profile_img,
  //   })
  //     .then((response) => dispatch(updateUserdata(response.data.user)))
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const onSubmit = (form) => {
    setLoading(true);
    console.log('PATCH /user');
    apiCall(`/user`, 'PATCH', form)
      .then(({ data: { user } }) =>
        dispatch(
          updateUserdata({
            ...usuario,
            ...user,
          })
        )
      )
      .catch((err) => console.error('## ERROR: ', err))
      .finally(() => setLoading(false));
  };

  return (
    <form className={styles.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.section}>
        <h2>Datos del establecimiento</h2>
        <div className={styles.block}>
          <TextInput
            label="Nombre del establecimiento"
            name="businessName"
            register={register}
            classes={{ container: styles.input2Column }}
          />
          <TextInput label="Teléfono" name="phone" register={register} />
          <TextInput label="Email" name="email" register={register} />
          <TextInput
            label="Dirección"
            name="address"
            register={register}
            classes={{ container: styles.input2Column }}
          />
          <TextInput label="Localidad" name="city" register={register} />
          <TextInput
            label="Código postal"
            name="postalCode"
            register={register}
          />
          <TextInput label="Provincia" name="province" register={register} />
        </div>
      </div>
      <div className={styles.section}>
        <h2>Datos del facturación</h2>
        <div className={styles.block}>
          <TextInput label="Manager" name="manager" register={register} />
          <TextInput
            label="Razón social"
            name="businessType"
            register={register}
          />
          <TextInput label="CIF" name="cif" register={register} />
        </div>
      </div>

      <Button
        // variant="contained"
        label="Enviar"
        type="submit"
        loading={loading}
        classes={{ button: styles.button }}
      />
    </form>
  );
};

export default ProfilePage;
