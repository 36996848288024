import ReactDOM from 'react-dom/client';
import App from './App';

import { Provider } from 'react-redux';
import store from 'store';

import theme from 'UI/Theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { Authenticator } from '@aws-amplify/ui-react';
// import 'bootstrap/dist/css/bootstrap.css'
import './index.css';
import './assets/scss/styles.scss';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);
