import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { RESET_STORE } from '@store/actions';
import { Config } from './configSlice';
import { RootState } from '..';
import { Carta } from '@features/Carta/domain/carta';
import { Category } from '@features/Category/domain/category';
import { Dish } from '@features/Dish/domain/dish';

const initialState: Carta = {
  selectedMenu: null,
  carta: null,
};

export const generateCarta = createAsyncThunk<
  Carta, // El tipo de retorno de la acción asíncrona, asumo que Carta es un array
  Config, // El argumento de la acción asíncrona, que ya está definido
  { state: RootState } // El tipo para el thunkAPI
>(
  'carta/generateCarta',
  async (config: Config, { getState }) => {
    const categoriesMap: Record<string, Category> | undefined =
      config.categories?.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur }),
        {} as Record<string, Category>
      );

    const dishesMap: Record<string, Dish> | undefined = config.dishes?.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {} as Record<string, Dish>
    );

    const { selectedMenu } = getState().carta;
    // Asumiendo que Carta es un array de algún tipo que incluye categorías y subRows
    const newCarta: Carta = selectedMenu.categories?.reduce((acc, {id}) => {
      const category = categoriesMap ? categoriesMap[id] : undefined;
      const dishes = category?.dishes?.reduce((subAcc, {id}) => {
        const dish = dishesMap ? dishesMap[id] : undefined;
        if (dish) {
          subAcc.push(dish);
        }
        return subAcc;
      }, [] as Dish[]);
      if (category && dishes) {
        acc.carta.push({
          ...category,
          dishes
        });
      }
      return acc;
    }, {} as Carta);
    return newCarta;
  }
);

export const cartaSlice = createSlice({
  name: 'carta',
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setCarta: (state, action) => {
      state.carta = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(generateCarta.fulfilled, (state, action) => {
  //       state.carta = action.payload;
  //     })
  //     .addCase(generateCarta.rejected, (state, action) => {
  //       state.carta = action.payload;
  //     })
  //     .addCase(RESET_STORE, () => initialState);
  // },
});

// Action creators are generated for each case reducer function
export const { setSelectedMenu, setCarta } = cartaSlice.actions;

export default cartaSlice.reducer;
