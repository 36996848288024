import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// import { resetStore } from '../actions'
import {
  RestaurantMenu as RestaurantMenuIcon,
  MonetizationOn as MonetizationOnIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  AccountCircle as AccountCircleIcon,
  QrCode2 as QrCode2Icon,
  Logout as LogoutIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import SidebarRoute from './SidebarRoute';
import { resetStore } from '@store/actions';
import { useDispatch } from 'react-redux';

const sections = [
  {
    title: 'Gestion',
    routes: [
      { label: 'Carta', icon: RestaurantMenuIcon, link: '/dashboard' },
      { label: 'QR', icon: QrCode2Icon, link: '/qr' },
    ],
  },
  {
    title: 'Datos',
    routes: [
      {
        label: 'Estadísticas',
        icon: AssessmentOutlinedIcon,
        link: '/estadisticas',
      },
      { label: 'Finanzas', icon: MonetizationOnIcon, link: '/finanzas' },
      { label: 'Nutrición', icon: LocalGroceryStoreIcon, link: '/nutricion' },
    ],
  },
  {
    title: 'Configuración',
    routes: [{ label: 'Perfil', icon: AccountCircleIcon, link: '/perfil' }],
  },
];
const SideBar = () => {
  const { usuario } = useSelector((state) => state.config);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={classNames('sidebar__container')}>
      <div className="sidebar__header">
        <div
          className="sidebar__avatar"
          style={{ backgroundColor: usuario?.profile?.backgroundColor }}
        >
          <span className="sidebar__avatar--initials">
            {usuario?.profile?.initials}
          </span>
        </div>
        <span className="sidebar__name">{usuario?.username}</span>
      </div>
      {sections.map(({ title, routes }) => (
        <div key={`section-${title}`} className="sidebar__section">
          <div className="sidebar__section--title">{title}</div>
          {routes.map(({ label, icon, link }) => (
            <SidebarRoute
              key={`route-${label}`}
              icon={icon}
              label={label}
              link={link}
            />
          ))}
        </div>
      ))}
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 40,
            marginTop: 10,
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          <Typography
            variant="h5"
            style={{
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 10,
              color: 'white',
            }}
          >
            {usuario.nombre_comercio}
          </Typography>
        </div>
      </div>
      <div>
        <SidebarRoute
          icon={LogoutIcon}
          label={'Cerrar sesión'}
          onClick={() => {
            Auth.signOut().then(() => {
              dispatch(resetStore());
              navigate('/');
              // clearStore()
              sessionStorage.clear();
            });
          }}
        />
      </div>
    </div>
  );
};

export default SideBar;
