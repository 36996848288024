import { font_text, respondTo } from 'assets/scss/mixins/mixins';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  width: 100%;

  ${({ classes }) =>
    css`
      ${classes?.container}
    `}
`;

export const Control = styled.div`
  position: relative;
  width: 100%;

  &:hover {
    .textarea__input {
      border: 1px solid #666;
    }
  }

  label {
    ${font_text}
    color: #888;
    cursor: text;
    font-size: 16px;
    padding-left: 10px;
    pointer-events: none;
    position: absolute;
    top: 12px;
    transform-origin: 0 0;
    transition: all 0.2s;

    span {
      color: $red;
      display: inline-block;
      padding-left: 5px;
    }

    ${({ classes }) =>
      css`
        ${classes?.label}
      `}

    &.active {
      color: #999;
      transform: scale(0.7) translate(5px, -6px);
    }
  }

  textarea {
    ${font_text}
    appearance: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    outline: none;
    padding: 22px 10px 0;
    resize: vertical;
    transition: border 0.2s ease-in-out;
    width: 100%;

    ${respondTo.tablet`
      font-size: 16px;
    `}

    &:focus {
      border: 1px solid #666;
    }

    &.readonly {
      background-color: #f2f2f2;
      pointer-events: none;
    }

    ${({ classes }) =>
      css`
        ${classes?.input}
      `}
  }

  ${({ classes }) =>
    css`
      ${classes?.control}
    `}
`;
