const ShellAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#DA4752"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <g>
      <path
        fill="#FEFEFE"
        stroke="#FEFEFE"
        strokeWidth={0.2788}
        strokeMiterlimit={22.3065}
        d="M108.2,35.2l-4,12.2c-2.8,10.2-4.6,21.3-6.6,32.3c-0.5,4-0.7,7.8-2,12l-1.7,9l-0.4,6.6l-0.6,9.7l-0.1,13.9l1.4,13.5l1.6,10.6 l3.3,13.1l3.7,8.7l1,2.4l2,1.6l-1.7-9.4l-4.4-13.1l-3.3-13.1l-1.4-9.2l-0.5-16.2c-0.1-5.8,0.4-10.2,0.9-14.6l1.2-11.8l3.5-16.8 l3.7-12.7l2.9-8.6c1.9-3.5,3.5-7,5.1-10c-1.4,3.2-2.6,6.9-3.7,10.9c-0.8,3-1.5,6.1-2,9.4l-2.5,14.7l-1.6,13l-1.1,9.1l-1.5,8.7 l-0.6,8.4l0.3,9.7l0.3,8.4l0.7,8.7l1.3,9.7l2.3,9.7l2.1,5.5l1,2.6l2.4-4.1l3.6-0.7l4.2,2.4l6.2,5.1l5.7,2.5h3.7l-2.3-4 c-0.4-3-1.2-6-0.8-9.1c0.4-6.4,0.9-12.7,1.9-18.6l1.3,11.9l0.7,9.4l1.3,10.7c2.7-0.9,5.6-1.6,8-2.7l6.7-3.3c1.8-4.1,4.2-8,4.7-12.7 l1.3-4.5c0.8,1.9,1.1,4.2,1,6.7l0.2,3.9l-0.2,2.8l5.4-4.7c2.1-2.4,3.3-4.5,4-6.4c1.5-3.1,2.2-5.9,2.6-8.8c0.9-3.7,1.4-7.8,1.4-12.3 l-0.4-17.4c-0.7-5.8-1.5-11.6-3.1-17.4c-1.2-4.9-3.2-10-5.1-15.1c-2.3-3.9-4.5-7.9-7.9-11.8c-3.3-4.4-7.2-8.6-12.2-12.7l-6.3-4.5 l-5.3-3.3l-6.5-3.4l-6.7-4.6l-5-5.1l-2-3.3L108.2,35.2z"
      />
      <g>
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M119.5,74.8v-2.9 l-0.5-3.3l-0.8-2.6l-1.2-4.4l-2-4.3l-0.7-2.4l-0.8-1.6l-0.2,3.4v2.7l0.3,3l0.9,3.6l1.2,3.4l1.8,2.8L119.5,74.8z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M133.7,61.4l2.2,2.3l2.5,2.7l3.2,3.3l2,2.5l1.8,2.3l1.5,2.2 l1.8,2.6l1.2,2.4l1.1,2.3l1,2.4l0.5,1.8l0.7,2.2l0.4,2.3l-2.3-3.3l-4.7-6l-2.8-3.9l-2.6-3.6l-2.4-3.6l-2.4-4.1l-2-3.9L133.7,61.4z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M138.9,92.5l1.4,3.6l1.9,5.2l1.3,4.3l1.7,4l1.5,4.1l1.5,3.5 l1.1,2.1l-0.6-5.3l-0.7-7.1l-0.7-4.1l-1.3-3.6l-1.4-3.1l-1.4-1.3l-2.8-2l-0.8-0.3L138.9,92.5z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M124.1,92.5L124,97v4.8l0.4,4.7l0.7,6.3l0.5,3.3l0.8,3.6 l0.6-6.3l0.7-5l0.1-3.5l-0.1-3.3l-0.4-3.1l-0.6-2.4l-1.1-1.9L124.1,92.5z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M115.4,126.8l-0.9-2.8l-0.7-4l-0.7-3.9l-0.6-3.8l-0.4-2.2 l-0.6-3.2l-0.7-2.8l-0.7,1.5l-0.8,2.7l-0.4,2.5l-0.1,2.1l0.1,2.8l0.7,3.3l1.2,2.8l1.4,2.1l1.2,1.5L115.4,126.8z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M137.1,139.1l0.7-2.1l0.4-1.8l0.3-2.3l0.2-2l0.1-2.3 l-0.1-1.9l-0.4-2.5l-0.4-3.1l-0.8-3.6l-0.8-1.9l-0.7,2.3l-0.4,2.8l-0.4,3.6v4.7l0.4,3.1l0.7,3l0.6,2L137.1,139.1z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M161.8,140.5l0.9-4.7l0.7-8.4l-0.2-5l-0.7-5.2l-1.6-4.6 l-1.7-2.8l-2-1.7l-1.8-0.7l1.2,2.3l0.9,2.6l0.7,2.9l0.6,3.5l0.7,5.5l0.4,4.1l0.4,3.5l0.4,2.9l0.3,1.5L161.8,140.5z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M143.7,164.9l1.4-1.7l1.3-2l0.8-3.6l0.1-3.8l-0.4-3.4 l-0.7-4.5l-0.5-2.6l-0.4-1.5l-0.9,2.3l-0.5,3.3l-0.4,3.9l-0.1,3v2.8v4.4v3v0.7L143.7,164.9z"
        />
        <path
          fill="#DA4752"
          stroke="#DA4752"
          strokeWidth={0.2788}
          strokeMiterlimit={22.3065}
          d="M110.6,136.1l0.5,3.2l0.7,3.3l1.3,3.3l1.7,4.4l1.5,3l1.6,3.1 l1.2,2.3l0.9,1.9l0.6,1.4l1.2,3.1l-3.2-3.4l-3.9-4.3l-2.8-4.4l-2.2-3.8l-1.1-5.2l0.1-2.2l0.4-2.3l0.8-2L110.6,136.1z"
        />
      </g>
    </g>
    <path
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M101.2,180.2l-5.4-7.6l-3.1-7c-2.5-5.5-3.5-11.4-4.6-17.4c-2.1-11.3-2.7-22.7-3.3-34L86,98c0.5-6.1,1.7-12,3.6-17.8l4.8-14.9 l6.3-16.6l1.4-4.7l-4.7,3.3l-7.7,4.9l-9.1,5.3c-3.7,1.8-6.6,4.2-9.3,6.8c-4.1,4.1-6.9,8.2-9.8,12.3c-2.9,5.5-5.5,11.7-7.9,18.2 c-1.5,4.8-2.8,9.7-3.8,14.5c-1.1,3.9-1.8,9.8-2.6,14.8l-0.9,14.3c0.3,2.9-0.3,5.8,1.1,8.7c0.1,2.3,2.2,4.6,3.5,6.8l6.1,6.6l2.6,2.4 l-3-5.2l-0.7-4.5l0.5-2.7c2.7,3.5,4.3,7,6.4,10.4l4.4,6.5l5.6,5.6c3.3,1.8,6.6,3.9,9.4,3.7l4.1,0.6c-0.9-3.4-2.6-7.4-1.3-10.8 l5.4,8.3l2.5-2.3c1.5,1.6,2.8,4.3,5.5,5.9C98.4,178.8,99.4,179.4,101.2,180.2L101.2,180.2z"
    />
    <path
      fill="#DA4752"
      stroke="#DA4752"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M78.7,70.4l1.8-2.7 l1.9-3.4l3-4.5c0.9-1,1.6-2.2,2.8-2.8l-2.8,0.4c-1.1,0.2-2,0.7-2.8,1.2c-1,0.7-1.4,1.3-1.9,2l-1.4,2.7l-1.4,3l-2.1,2.4l-2.3,2.4 l-0.8,2.1L72.5,75l1.6-0.7l2-1.3l1.2-1.3L78.7,70.4z"
    />
    <path
      fill="#DA4752"
      stroke="#DA4752"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M78.3,113.3l0.4-10.1 L79.7,93l0.6-4.9l1.1-5.9l1.2-4.7c-1.5,0.8-2.2,2.2-3.3,3.3l-2.3,5.5l-1,5.6l-0.3,5.3l0.5,5.5l0.6,4.4l1.4,5.9L78.3,113.3z"
    />
    <path
      fill="#DA4752"
      stroke="#DA4752"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M65,109 c0.5-1.5,1-3,1.1-4.7c0.2-2,0.5-3.9,0.3-6.2v-4.6l-0.9-5.4l-1.1-2.6L62.6,92l-0.1,5.3c-0.2,1.5,0.1,3.1,0.4,4.6l0.5,2.5l1.2,3.3 L65,109z"
    />
    <path
      fill="#DA4752"
      stroke="#DA4752"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M55,106.5l-0.1,3.8 l-0.1,11.9l0.5,7.5l0.7,6.1l0.7,6.9l-2.4-5.1c-1.1-2.8-1.9-5.5-2.6-8.3l-0.7-6.4v-3.9c0.2-2,0.4-4.1,1-6.1c0.4-1.5,1-2.9,1.7-4.4 C53.9,107.8,54.4,107.2,55,106.5L55,106.5z"
    />
    <path
      fill="#DA4752"
      stroke="#DA4752"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M67.6,124.5 c0.7,2,1,3.6,1.3,5.3l0.8,5.5l1.1,6.6l0.7,2.5l1.6,3.5l0.6,1.7l0.5,2.1l-0.1,2.8L73,154l-0.8-1.1l-0.6-1.8c-0.1-1-1.1-2-1.8-3 l-2.5-2.9l-1.2-2.5l-0.5-4.2v-3.1l1.4-9.1l0.2-2.3L67.6,124.5z"
    />
  </svg>
);

export default ShellAllergensIcon;
