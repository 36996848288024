import { Token } from '@features/User/domain/user';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '@store/actions';
import { Auth } from 'aws-amplify';

const initialState = {
  isAuthenticated: localStorage.getItem('barToken') ? true : false,
  token: undefined,
};

export const initAuth = createAsyncThunk('auth/initAuthStatus', async () => {
  try {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const {
          signInUserSession: {
            idToken: { jwtToken: idToken },
            accessToken: { jwtToken: accessToken },
          },
        } = user;
        return ({ idToken, accessToken } as Token);
      })

  } catch (e) {
    throw e
  }
      
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initAuth.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(RESET_STORE, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { saveToken, setAuthentication } = authSlice.actions;

export default authSlice.reducer;
