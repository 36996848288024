import styled, { css } from 'styled-components';
import { font_text, font_text_bold } from 'assets/scss/mixins/mixins';
import { colors } from 'assets/scss/variables/variables';
import { createUseStyles } from 'react-jss';

const handleColorProp = ({ color, variant }) => {
  switch (color) {
    case 'primary':
      return css`
        color: ${['contained', 'outlined'].includes(variant)
          ? '#fff'
          : colors.primaryMain};

        ${['contained', 'outlined'].includes(variant) &&
        css`
          background: ${colors.primaryMain};

          span {
            color: #fff;
          }

          &:before {
            background: rgba(0, 0, 0, 0.1);
            color: #fff;
          }
        `}
      `;
    case 'secondary':
      return css`
        background: ${variant === 'outlined'
          ? 'transparent'
          : colors.terciaryMain};

        color: ${variant === 'outlined' ? colors.terciaryMain : '#333'};
        padding: 0 30px;

        span {
          align-items: center;
          display: inline-flex;
          gap: 5px;
        }

        b {
          @include ff_text_bold();
          font-weight: 100;

          svg {
            g {
              fill: white;
            }
          }
        }

        &:before {
          background: ${variant === 'outlined'
            ? 'rgba(0, 0, 0, .05)'
            : 'rgba(0, 0, 0, 0.1)'};
        }

        &:hover {
          span {
            color: #333;
          }

          &::before {
            color: #333;
          }
        }
      `;
    default:
      return '';
  }
};

export const StyledButton = styled.button`
  ${({ variant, size }) => {
    switch (true) {
      case ['outlined', 'contained'].includes(variant):
        return css`
          border-radius: 16px;
          cursor: pointer;
          height: 46px;
          overflow: hidden;
          padding: 0 30px;
          position: relative;
          text-decoration: none;
          user-select: none;

          g {
            fill: white;
          }
        `;
      case variant === 'link':
        return css`
          background: transparent;
          border: 0;

          span {
            ${font_text}
            background: transparent;
            border: 0;
            color: ${colors.primaryMain};
            cursor: pointer;
            font-size: 16px;
            position: relative;
            text-decoration: none;

            &:hover {
              color: ${colors.primaryDarker};
              text-decoration: none;

              &::after {
                background: ${colors.primaryDarker};
                width: 0;
              }
            }

            &::after {
              background: ${colors.primaryMain};
              bottom: 0;
              content: '';
              height: 1px;
              left: 0;
              position: absolute;
              right: 0;
              transition: all 0.3s;
              width: 100%;
            }
          }
        `;
      case variant === 'text':
        return css`
          ${font_text_bold}
          background-color: transparent;
          border-width: 0;
          cursor: pointer;
          margin-bottom: 12px;
          transform-origin: 50% 50%;
          transition: all 300ms ease-in-out;

          &:hover {
            transform: scale(1.06);
          }
        `;

      case variant === 'icon':
        return css`
          align-items: center;
          background-color: transparent;
          border-radius: 30px;
          border-width: 0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          position: relative;
          transition: all 300ms ease-in-out;

          &:hover {
            transform: scale(1.1);
          }

          &:active {
            background-color: #bbb;
          }

          ${size === 'small' &&
          css`
            height: 24px;
            min-height: 24px;
            min-width: 24px;
            padding: 0;
            width: 24px;
          `}

          ${size === 'medium' &&
          css`
            height: 28px;
            min-height: 28px;
            min-width: 28px;
            padding: 0;
            width: 28px;
          `}
        `;
      default:
        return '';
    }
  }}

  ${({ color, variant }) => {
    if (
      (variant === 'contained' ||
        variant === 'outlined' ||
        variant === 'text') &&
      ['primary', 'secondary'].includes(color)
    ) {
      return css`
        ${handleColorProp({ color, variant })}
        ${font_text_bold};
        border: ${color === 'secondary' && variant === 'outlined'
          ? '2px solid'
          : '0 solid transparent'};
        font-size: 16px;
        font-weight: 500;
        transition: all 0.13s;

        span {
          position: relative;
          z-index: 1;
        }

        &:hover {
          transition: all 0.3s;

          &::before {
            opacity: 1;
            transition: all 0.2s;
            width: 100%;
          }
        }

        &:before {
          border: 0 solid transparent;
          border-radius: 12px;
          bottom: 0;
          content: '';
          left: 0;
          opacity: 0;
          position: absolute;
          transition: all 0.62s;
          width: 100%;
        }
      `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          ${font_text_bold}
          font-size: 12px;
          height: 30px;
          line-height: 12px;
          max-height: 30px;
          padding: 0 20px;
        `;
      default:
        return '';
    }
  }}}

  ${({ classes }) => css`
    ${classes.button}
    span {
      ${classes.label}
    }
  `}
`;

export const useStyles = createUseStyles({
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  loading: {
    opacity: 0.5,
    position: 'relative',

    '&>div': {
      margin: '0 auto',
      position: 'absolute',
      zIndex: 10,
      right: 'calc(50% - 16px)',
      top: 'calc(50% - 8px)',
    },
  },
});
