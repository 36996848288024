import CeleryAllergensIcon from '@UI/Icons/Allergens/CeleryAllergensIcon';
import CrustaceanAllergensIcon from '@UI/Icons/Allergens/CrustaceanAllergensIcon';
import EggAllergensIcon from '@UI/Icons/Allergens/EggAllergensIcon';
import FishAllergensIcon from '@UI/Icons/Allergens/FishAllergensIcon';
import GlutenAllergensIcon from '@UI/Icons/Allergens/GlutenAllergensIcon';
import LupinsAllergensIcon from '@UI/Icons/Allergens/LupinsAllergensIcon';
import MilkAllergensIcon from '@UI/Icons/Allergens/MilkAllergensIcon';
import MollusksAllergensIcon from '@UI/Icons/Allergens/MollusksAllergensIcon';
import MustardAllergensIcon from '@UI/Icons/Allergens/MustardAllergensIcon';
import PeanutAllergensIcon from '@UI/Icons/Allergens/PeanutAllergensIcon';
import SesameAllergensIcon from '@UI/Icons/Allergens/SesameAllergensIcon';
import ShellAllergensIcon from '@UI/Icons/Allergens/ShellAllergensIcon';
import SojaAllergensIcon from '@UI/Icons/Allergens/SojaAllergensIcon';
import SulfurAllergensIcon from '@UI/Icons/Allergens/SulfurAllergensIcon';

export const ALLERGENS = [
  {
    name: 'soja',
    icon: <SojaAllergensIcon size={20} />,
    label: 'Soja',
  },
  {
    name: 'sesame',
    icon: <SesameAllergensIcon size={20} />,
    label: 'Sésamo',
  },
  {
    name: 'fish',
    icon: <FishAllergensIcon size={20} />,
    label: 'Pescado',
  },
  {
    name: 'mustard',
    icon: <MustardAllergensIcon size={20} />,
    label: 'Mostaza',
  },
  {
    name: 'gluten',
    icon: <GlutenAllergensIcon size={20} />,
    label: 'Gluten',
  },
  {
    name: 'crustacean',
    icon: <CrustaceanAllergensIcon size={20} />,
    label: 'Crustáceos',
  },
  {
    name: 'mollusks',
    icon: <MollusksAllergensIcon size={20} />,
    label: 'Moluscos',
  },
  {
    name: 'milk',
    icon: <MilkAllergensIcon size={20} />,
    label: 'Leche',
  },
  {
    name: 'egg',
    icon: <EggAllergensIcon size={20} />,
    label: 'Huevo',
  },
  {
    name: 'lupins',
    icon: <LupinsAllergensIcon size={20} />,
    label: 'Altramuces',
  },
  {
    name: 'shell',
    icon: <ShellAllergensIcon size={20} />,
    label: 'Frutos secos',
  },
  {
    name: 'peanut',
    icon: <PeanutAllergensIcon size={20} />,
    label: 'Cacahuete',
  },
  {
    name: 'celery',
    icon: <CeleryAllergensIcon size={20} />,
    label: 'Apio',
  },
  {
    name: 'sulfur',
    icon: <SulfurAllergensIcon size={20} />,
    label: 'Azufre',
  },
];
