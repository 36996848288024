const LupinsAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#D98805"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M114.1,57.2l3.2-7.1l3.5-6.3l5-5.7l3.6-3.6l6.4-4.5l5.5-2.5c2.3-0.4,4.8-1.2,7-1.3l4.3-0.1l6.6,0.9l7.2,2.5l4.5,2.6 c3.1,1.9,5.1,3.7,7.4,5.5l5.6,6.2l3.5,5.3c2.1,4.2,3.7,8.3,5,12.5l1,7.4l-0.5,7.8l-2.9,8.9l-4.2,7.6l-6.2,7l-7.3,4.9 c-3.2,2-6.3,2.9-10.8,4.1c-4,0.6-8.3,0.4-12.5,0.6l-9.3-1.6c-3.5-1-6.8-2.3-9.8-3.9c-3.6-2.1-6.7-4.5-9.5-7c-2-1.9-3.1-3.9-4.5-5.8 c-1.8-2.9-2.6-6.2-3.9-9.4c-0.7-3.5-1.1-7-0.9-10.5l1.1-7.2C112.6,62.3,113.3,59.9,114.1,57.2L114.1,57.2z"
    />
    <path
      fill="#D98805"
      stroke="#FADD3D"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M120.5,70.7c0.6,2.3,0.9,4.8,2,6.7l3.2,5.2l4.1,4.1l4.8,3.4l4.8,2.3l6.1,1.8l4.1,0.5l6.4,0.9l7.7-1.1l5.7-1.9l5-3.4l5.5-5.2l2.7-5 l2.3-4.8l-0.6,4.5l-0.8,3.6l-1.6,4.1l-3,4.5c-1.6,2.1-3.4,4-5.2,5.7l-3.2,2.3l-6.1,3.2l-4.1,1l-4.5,0.6l-6.4,0.4l-7.7-1.8l-6.3-1.9 l-6.1-3.8c-1.5-1.3-2.9-3-4.6-4.8c-1.8-2.1-2.7-4.2-3.9-6.4c-0.7-2.2-0.9-4.6-1.1-7V75L120.5,70.7z"
    />
    <path
      fill="#D98805"
      stroke="#FADD3D"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M161.3,33.1l4.1-0.9 l0.7-0.2c0.3-0.2,0.2-0.7,0-1.4l-3.2-1.1l-6.8-0.7h-3.9l-3.2,0.7l-0.7,0.9c1,1.2,2.3,1.3,3.2,1.9l4.5,0.6L161.3,33.1z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M23.3,100l2-7.5l2.4-6.8l4-6.4l3-4.1l5.6-5.5l5-3.4c2.2-0.8,4.5-2,6.7-2.4l4.2-0.8l6.7-0.2l7.5,1.3l4.9,1.8c3.4,1.4,5.6,2.8,8.2,4.2 l6.5,5.2l4.3,4.6c2.8,3.8,5,7.6,7,11.5l2.2,7.1l0.8,7.8l-1.4,9.3l-2.9,8.2l-5,7.9l-6.6,6.3c-2.8,2.5-5.7,3.9-10,5.8 c-3.8,1.3-8.1,1.8-12.2,2.7l-9.4,0c-3.6-0.4-7.1-1.1-10.3-2.2c-3.9-1.5-7.4-3.3-10.5-5.3c-2.3-1.5-3.7-3.3-5.4-5 c-2.3-2.6-3.6-5.7-5.4-8.6c-1.3-3.3-2.2-6.7-2.6-10.2l-0.1-7.3C22.6,105.2,22.9,102.8,23.3,100L23.3,100z"
    />
    <path
      fill="#D98805"
      stroke="#FADD3D"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M31.8,112.2c1,2.2,1.7,4.6,3.1,6.3l4,4.6l4.7,3.4l5.3,2.6l5.1,1.5l6.3,0.8l4.1-0.2l6.5-0.2l7.4-2.4l5.3-2.8l4.4-4.2l4.6-6l1.8-5.4 l1.5-5.1l0,4.7l-0.2,3.7l-0.9,4.3l-2.2,4.9c-1.2,2.3-2.7,4.5-4.2,6.5l-2.8,2.8l-5.5,4.2l-3.9,1.7l-4.3,1.3l-6.2,1.5l-7.9-0.5 l-6.5-0.8l-6.6-2.6c-1.7-1-3.4-2.5-5.3-4c-2.1-1.8-3.4-3.7-4.9-5.7c-1.1-2.1-1.6-4.4-2.2-6.7l-0.6-3.4L31.8,112.2z"
    />
    <path
      fill="#D98805"
      stroke="#FADD3D"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M65.9,68.4l3.9-1.6 l0.7-0.3c0.3-0.2,0.1-0.7-0.2-1.4l-3.3-0.6L60,65l-3.8,0.6l-3,1.2l-0.5,1c1.2,1,2.5,0.9,3.5,1.3l4.5-0.2L65.9,68.4z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M171,135.4l2.5,4.4l1.5,7.5l-0.6,6.7l-3.3,8.3l-2.3,4.2l-1.8,3.1c-1,2.1-1.6,2.2-2.8,4l-3.3,3.6l-4,3.5l-3.2,2.6l-3.6,2.2 c-3.4,1.4-2.8,1.7-5.6,2.5l-5,1.3l-6.6,0.8c-4.6-0.8-5.7-0.9-9.8-2.3l-7.3-4.7l-4-3.8l-4.7-6.2l-2.7-5.8l-2.4-7.7l-0.1-8.4 c0.3-3.8,0.4-6.4,2-10.8c1.9-3.6,3.2-7,5.5-10.5l6.5-8c2.9-2.3,5.8-3.3,8.9-4.7c3.8-1.7,6.6-1.2,10.4-2c2.7-0.5,5.4,0.4,7.8,0.5 c3.4,0.3,7.2,3.1,10.5,4c3.3,1.4,4,2.5,6.7,4.7l5,3.7C166.6,129.6,169.3,133.1,171,135.4L171,135.4z"
    />
    <path
      fill="#D98805"
      stroke="#FADD3D"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M133.5,124.1c0,0,0,0-1.7,1.3l-1.8,1.5l-1.9,1.8l-1.6,1.5l-2.1,2l-1.8,2.2l-1.9,2.3l-3.8,5.9l-2.7,6.6l-1.3,7.1v6.1 c0.1,2.4,0.8,4.9,1.5,7.4l2.1,5.3l2.5,4.6l-3.6-3.2l-2.5-2.8l-2.6-3.7l-2.4-5.1c-0.8-2.6-1.6-5.1-1.9-7.6l-0.1-3.8l0.7-7l1.3-5.3 l2.1-4.3l3.4-5.6c1.3-2,3.1-3.9,5.4-5.6c1.3-1.8,3.8-2.6,5.9-3.7c1.6-0.8,3.8-1.3,6.4-1.6c2-0.4-0.2,0.1,2.3-0.3 c2.8-0.2,2.5-0.3,4.9,0c2.4,0.7-1.7,0.5-2.2,1.9l-1.4,1.2L133.5,124.1z"
    />
    <path
      fill="#D98805"
      stroke="#FADD3D"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M156.1,174.1l-1.7,3.8 l-0.2,0.7c0,0.3,0.5,0.6,1.1,0.8c1,0.1,2-0.8,3-1.4c3.9-4.7,2.8-2.4,4.9-5.8l1.7-3l1.3-3l-0.3-1.1c-1.6-0.7-2.4,0.9-3.4,1.4 l-3.1,3.2L156.1,174.1z"
    />
  </svg>
);

export default LupinsAllergensIcon;
