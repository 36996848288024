import { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { deepValue } from '../utils';
import { Control, Container } from './styles';

const Textarea = ({
  refs = undefined,
  title = '',
  name,
  placeholder = undefined,
  classes,
  defaultValue,
  required,
  readOnly = false,
  errors,
  onChange = undefined,
  hideErrors,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(defaultValue || refs.current?.value);
  const itemErrors = name && deepValue(errors, name);

  const handleOnChange = (e) => {
    if (onChange) onChange(e);
    if (setValue) setValue(e.target.value);
  };

  return (
    <Container classes={classes.container}>
      <Control
        classes={classes}
        onFocus={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsFocused(true);
        }}
        onBlur={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsFocused(false);
        }}
      >
        <label
          className={classnames({
            active: !!value || isFocused || placeholder,
          })}
        >
          {title}
          {required && <span>*</span>}
        </label>
        <textarea
          data-cy={name || ''}
          ref={refs}
          id={name}
          name={name}
          placeholder={placeholder && !isFocused ? placeholder : null}
          type={'text'}
          defaultValue={defaultValue ?? ''}
          readOnly={readOnly}
          className={classnames({
            readonly: readOnly,
          })}
          onChange={handleOnChange}
          rows={5}
        />
      </Control>
      {itemErrors && (
        <span className={classnames(classes.error, 'rform__item--error')}>
          {itemErrors.message}
        </span>
      )}
      {!hideErrors && !itemErrors && (
        <div className="rform__item--error-empty" />
      )}
    </Container>
  );
};

Textarea.propTypes = {
  /** Reference to this input component.*/
  refs: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /** Title of the number input. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Name of this element to reference it on react-hook-form.*/
  name: PropTypes.string,
  /** Text shown when the input is empty. */
  placeholder: PropTypes.string,
  /** Object with classes to extend and/or override.*/
  classes: PropTypes.object,
  /** Default name of the file.*/
  defaultValue: PropTypes.node,
  /** When true it is mandatory to this component to have a value.*/
  required: PropTypes.bool,
  /** When true the component exists but is not shown.*/
  hidden: PropTypes.bool,
  /** If true this component is in read-only mode.*/
  readOnly: PropTypes.bool,
  /** This prop indicates that the element is the only one in a column.*/
  singleCol: PropTypes.bool,
  /** Error object from react-hook-form.**/
  errors: PropTypes.object,
  /** Method to execute when the value changes. This function returns (e) => {}.*/
  onChange: PropTypes.func,
  /** In case that you don't want to show errors or dont want to keep the space at the bottom where errors are shown.*/
  hideErrors: PropTypes.bool,
};

export default Textarea;
