const EggAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 219 219"
    fill="#F68D2F"
  >
    <ellipse cx="109.5" cy="109.5" rx="109.5" ry="109.5" />
    <path
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M23.6,106.7l2.6-11.3l2.8-8.6c1.4-3,2.5-6.3,4.9-10l4.7-6c1.3-1.8,3.4-3.3,5.3-4.9l6.2-2.6c2.3-0.4,4.6-0.3,6.9,0.2 c2.4,0.7,4,1.4,5.5,2.1c2.4,1.2,4.3,2.6,6.2,4.1l5.8,6.2c2.5,2.5,4.2,5.4,6,8.3l3.4,5.8l3,6.8l3.2,9l2.1,9.2l0.6,5.6v5.8l-0.9,5.3 l-1.1,5.1l-1.5,4.7l-3.4,6l-3.6,4.1l-4.5,3.9l-5.3,3.4l-7.1,1.9c-3.3,0.7-5.6,0.2-7.7,0.7l-9-0.9l-8.3-4.1l-6.4-4.5 c-2.6-3.4-5-6.7-6.8-10.1l-3.2-8.1l-1.1-6l-0.2-9.1l0.4-6.1L23.6,106.7z"
    />
    <path
      fill="#F68D2F"
      stroke="#F68D2F"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M44,97 l1.8-5.9l2.4-4.7l3.7-4.5l3.8-2.5l1.1-1.3l0.4-2.8c-0.1-0.6-0.8-1.5-2-2.5l-1.7-0.7h-1.8l-3.7,2.5l-2.1,2l-2.1,2l-2.5,3.2L39.6,85 l-1.1,3.1L37,91.7l-1,4l-0.4,2.8v1l1.3,1.3l1.6,1.1l2.5,0.3l2.2-1.4l0.5-1.8L44,97z"
    />
    <path
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M150.3,96.7l11.5,1.6l8.8,2.1c3.1,1.1,6.5,2,10.3,4.1l6.4,4.2c1.9,1.1,3.6,3.1,5.4,4.9l3.1,6c0.6,2.3,0.6,4.5,0.3,6.9 c-0.5,2.4-1.1,4.1-1.7,5.7c-1,2.5-2.3,4.5-3.5,6.5l-5.7,6.3c-2.3,2.7-5.1,4.6-7.8,6.6l-5.5,3.9l-6.6,3.5l-8.7,3.9l-9,2.9L142,167 l-5.8,0.5l-5.4-0.4l-5.2-0.6l-4.8-1.1l-6.2-2.9l-4.3-3.3l-4.2-4.2l-3.8-5.1l-2.4-6.9c-1-3.2-0.7-5.5-1.3-7.6l0.1-9l3.4-8.6l4-6.8 c3.1-2.9,6.3-5.5,9.5-7.6l7.8-3.9l5.9-1.6l9.1-1l6.1-0.1L150.3,96.7z"
    />
    <path
      fill="#F68D2F"
      stroke="#F68D2F"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M161.7,116.2l6.1,1.3l4.8,2l4.8,3.3l2.8,3.6l1.4,1l2.8,0.2c0.6-0.1,1.4-0.9,2.3-2.2l0.6-1.7l-0.1-1.8l-2.8-3.5l-2.1-1.9l-2.1-1.9 l-3.4-2.3l-3.2-1.4l-3.2-0.9l-3.8-1.1l-4-0.7l-2.8-0.2l-1,0.1l-1.2,1.4l-1,1.6l-0.1,2.5l1.6,2.1l1.8,0.3L161.7,116.2z"
    />
    <path
      fill="#FEFEFE"
      stroke="#FEFEFE"
      strokeWidth={0.2788}
      strokeMiterlimit={22.3065}
      d="M81.8,71.1 c1.9-5.8,4.5-11.2,7.6-16.3c3.7-5.8,7.4-11.6,11.3-15.9c3.3-3.6,6.9-6.8,11.7-8.5c3-1.9,6.1-2.2,9.1-2.1c3.6,0.7,7.1,2.9,10.2,5.1 c3.4,3.1,5.2,5.5,6.5,7.7c2.5,4.6,5.1,9.3,6.4,13.9l3,11.5c0.7,4.5,1.4,10.2,2.1,16.4v6l-8.4-0.7c-4.6,0-9.4-0.2-12.6,0.5 c-5.4,0.2-9.6,2.4-14.1,4.1l-7.4,4.9l-7.3,6.4l-5.7-13.6c-1-2.4-2.8-5.6-5-9.2C87.1,78.1,85.1,75.4,81.8,71.1L81.8,71.1z"
    />
    <path
      fill="#F68D2F"
      stroke="#F68D2F"
      strokeWidth={0.2788}
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M108.3,60.7l2.5-5.7l2.9-4.3l4.2-4l4.1-2l1.3-1.2l0.7-2.7c0-0.6-0.6-1.5-1.7-2.7l-1.6-0.9l-1.8-0.2L115,39l-2.3,1.7l-2.3,1.7 l-2.9,2.9l-2.1,2.9l-1.5,2.9l-1.8,3.5l-1.5,3.8l-0.8,2.8l-0.1,1l1.1,1.4l1.4,1.3l2.4,0.6l2.3-1.1l0.7-1.7L108.3,60.7z"
    />
  </svg>
);

export default EggAllergensIcon;
