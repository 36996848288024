import { configureStore } from '@reduxjs/toolkit';
import cartaSlice from './slices/cartaSlice';
import authSlice from './slices/authSlice';
import configSlice from './slices/configSlice';

import { configApi } from './services/configService';
import { dishApi } from './services/dishService';
import { cartaApi } from './services/cartaService';

const store = configureStore({
  reducer: {
    auth: authSlice,
    config: configSlice,
    carta: cartaSlice,
    [configApi.reducerPath]: configApi.reducer,
    [dishApi.reducerPath]: dishApi.reducer,
    [cartaApi.reducerPath]: cartaApi.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    configApi.middleware,
    dishApi.middleware,
    cartaApi.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
