import DragHandleIcon from '@mui/icons-material/DragHandle';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DishesTable from './DishesTable';
import useCarta from '@hooks/useCarta';
import { useStyles } from './styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CartaTable = ({ expandedRows, handleExpandClick }) => {
  const styles = useStyles();

  const { carta, handleDragEnd } = useCarta({
    element: 'categoria',
    onDrag: 'reorder',
  });

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="carta-body">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {carta?.map((category, index) => {
              const isExpanded = expandedRows.includes(index);
              return (
                <Draggable
                  draggableId={category.id}
                  key={category.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={classNames(styles.tableGroup, {
                        show: isExpanded,
                      })}
                    >
                      <div className={styles.tableItem}>
                        <span
                          onClick={() => handleExpandClick(index)}
                          className={classNames(styles.tableExpand, {
                            expanded: isExpanded,
                          })}
                        >
                          <ArrowForwardIosIcon fontSize="12" />
                        </span>
                        <div
                          className={classNames(styles.tableItemContent, {
                            show: isExpanded,
                          })}
                        >
                          <span
                            {...provided.dragHandleProps}
                            aria-label="move"
                            role="img"
                            className={classNames(styles.tableDragIcon, {
                              active: snapshot.isDragging,
                            })}
                          >
                            <DragHandleIcon />
                          </span>
                          <span className={styles.categoryName}>
                            {category.name}
                          </span>
                          <span>{`${category.subRows.length} platos`}</span>
                          <span className={styles.moreButton}>
                            <MoreVertIcon />
                          </span>
                        </div>
                      </div>
                      {category.subRows && (
                        <div
                          className={classNames(styles.tableDishContainer, {
                            show: isExpanded,
                          })}
                        >
                          {category.subRows.length > 0 ? (
                            <DishesTable category={category} />
                          ) : (
                            <span>Aún no hay platos en esta categoría</span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CartaTable;
