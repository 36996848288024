import { useTable } from 'react-table';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const UpDownArrow = ({ classes, dragHandleProps }) => (
  <span
    {...dragHandleProps}
    className={classNames(classes.container)}
    aria-label="move"
    role="img"
  >
    <DragIndicatorIcon />
  </span>
);

const Table = ({
  columns,
  data,
  hideHeader = false,
  classes = {},
  reorderData,
  draggableRows = false,
  handleDragEnd = () => {},
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    reorderData,
  });

  return (
    <table className={classNames('table', classes.table)} {...getTableProps()}>
      <thead className={classNames(classes.header)}>
        {headerGroups.map((headerGroup) => {
          const { key, role } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} role={role}>
              {headerGroup.headers.map((column) => {
                const { key, ...other } = column.getHeaderProps();
                return (
                  <th key={key} {...other}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="table-body">
          {(provided, snapshot) => (
            <tbody
              ref={provided.innerRef}
              className={classNames(classes.body)}
              {...provided.droppableProps}
            >
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <Draggable
                    draggableId={row.original.id}
                    key={row.original.id}
                    index={row.index}
                  >
                    {(provided, snapshot) => {
                      return (
                        <tr
                          className={classNames('table__row', classes.row, {
                            ['is-dragging']: snapshot.isDragging,
                          })}
                          {...row.getRowProps()}
                          {...provided.draggableProps}
                          // {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {row.cells.map((cell, index) => {
                            const { key, ...others } = cell.getCellProps();
                            return (
                              <td
                                key={key}
                                className={classNames(classes.data)}
                                {...others}
                              >
                                <div
                                  className={classNames(
                                    'table__cell',
                                    classes.cell
                                  )}
                                >
                                  {draggableRows && !index && (
                                    <UpDownArrow
                                      classes={{
                                        container: 'table__drag--container',
                                      }}
                                      {...{
                                        dragHandleProps:
                                          provided.dragHandleProps,
                                        isSomethingDragging:
                                          snapshot.isDraggingOver,
                                      }}
                                    />
                                  )}
                                  {cell.render('Cell')}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
  );
};

export default Table;
