import { createApi } from '@reduxjs/toolkit/query/react';
import { apiCallRTK } from '@utils/apiTools';

export const dishApi = createApi({
  reducerPath: 'dishApi',
  baseQuery: apiCallRTK,
  tagTypes: ['Dish'],
  keepUnusedDataFor: 120,
  endpoints: (builder) => ({
    getDishes: builder.query({
      query: () => ({ url: `/dish` }),
      providesTags: (result, error, id) => [
        ...(result
          ? result.data.dishes.map((el) => ({
              type: 'Dish',
              id: el.id,
            }))
          : []),
        { type: 'Dish', id: 'PARTIAL-LIST' },
      ],
    }),
    getDishById: builder.query({
      query: (dishId) => ({ url: `/dish/${dishId}` }),
      providesTags: (result, error, dishId) => {
        if (!error) {
          return [{ type: 'Dish', id: dishId }];
        }
      },
    }),
    createDish: builder.mutation({
      query: (body) => ({
        url: `/dish/${body.dishId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, body) => [
        { type: 'Dish', id: body.dishId },
        { type: 'Dish', id: 'PARTIAL-LIST' },
      ],
    }),
    updateDish: builder.mutation({
      query: (body) => ({
        url: `/dish/${body.dishId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, body) => [
        { type: 'Dish', id: body.dishId },
      ],
    }),
    uploadDishImage: builder.mutation({
      query: ({ dishId, imgData }) => ({
        url: `/dish/image`,
        method: 'POST',
        body: { dishId, imgData },
      }),
      invalidatesTags: (result, error, body) => [
        { type: 'Dish', id: body.dishId },
      ],
    }),
  }),
});

export const {
  useGetDishesQuery,
  useGetDishByIdQuery,
  useCreateDishMutation,
  useUpdateDishMutation,
  useUploadDishImageMutation,
} = dishApi;
