import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCarta } from '@store/slices/cartaSlice';
import { getMenus } from '@store/slices/configSlice';
import useAPI from './useAPI';

export default function useCarta({
  element = 'categoria',
  onDragAction = 'reorder',
}) {
  const { selectedMenu, carta } = useSelector((state) => state.carta);
  const { categoriesMap, dishesMap } = useSelector((state) => state.config);

  const { apiCall } = useAPI();
  const dispatch = useDispatch();

  const elementToUpdate = {
    categoria: 'menu',
    plato: 'categoria',
  };

  const arrayToUpdate = {
    categoria: 'categories',
    plato: 'dishes',
  };

  useEffect(() => {
    dispatch(
      setCarta(
        selectedMenu?.categories?.reduce((acc, curr) => {
          acc.push({
            ...categoriesMap[curr],
            subRows: categoriesMap[curr].dishes?.reduce((acc, curr) => {
              if (dishesMap?.[curr]) {
                acc.push(dishesMap[curr]);
              }
              return acc;
            }, []),
          });
          return acc;
        }, [])
      )
    );
  }, [selectedMenu, categoriesMap, dishesMap, dispatch]);

  const reorderData = (startIndex, endIndex) => {
    const newData = [...carta];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    dispatch(setCarta(newData));
    return newData;
  };

  const handleDragEnd = ({ source, destination }) => {
    let newData;
    if (!destination) return;
    switch (onDragAction) {
      case 'reorder':
        newData = reorderData(source.index, destination.index);
        break;
      default:
        break;
    }
    apiCall(
      `/carta/${elementToUpdate[element]}/update/${selectedMenu.id}`,
      'PATCH',
      {
        [arrayToUpdate[element]]: newData.map((el) => el.id),
      }
    )
      .then(() => dispatch(getMenus()))
      .catch((err) => console.error('## ERROR on POST /carta/menu: ', err));
  };

  return {
    // data,
    carta,
    reorderData,
    handleDragEnd,
  };
}
