import { font } from 'assets/scss/helpers/fonts';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  summary: {
    padding: {
      top: 24,
      right: 36,
      bottom: 0,
      left: 36,
    },
    marginBottom: 50,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 60,
  },
  menuSelect: {
    width: 560,
  },
  timetable: {
    ...font.textRegular,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 18,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    position: 'relative',
  },
});
