import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCall } from '@utils/apiCalls';
import { setSelectedMenu, generateCarta } from './cartaSlice';
import { RESET_STORE } from '@store/actions';
import { RootState } from '..';
import { User } from '@features/User/domain/user';
import { Dish } from '@features/Dish/domain/dish';
import { Category } from '@features/Category/domain/category';

export interface Config {
  categories: Category[];
  dishes: Dish[];
  menus: Menu[];
  categoriesMap: object | undefined;
  dishesMap: object | undefined;
  menusMap: object | undefined;
  usuario: User | undefined;
}

interface Menu {
  id: string;
}

const initialState: Config = {
  menus: [],
  categories: [],
  dishes: [],
  categoriesMap: undefined,
  dishesMap: undefined,
  menusMap: undefined,
  usuario: undefined,
};

export const getConfig = createAsyncThunk<any, Config, { state: RootState }>(
  'config/getConfig',
  async (_, { getState, dispatch }) => {
    try {
      const selectedMenu = getState().carta.selectedMenu;
      const response = await apiCall('/config', 'GET');

      // Maneja el caso en el que selectedMenu no está definido
      if (!selectedMenu) {
        await dispatch(setSelectedMenu(response.data.menus[0]));
        await dispatch(generateCarta(response.data));
        return response.data;
      }

      // Si selectedMenu ya está definido, actualízalo y genera la carta
      const updatedMenu = response.data.menus.find(
        (menu) => menu.id === selectedMenu.id
      );
      await dispatch(setSelectedMenu(updatedMenu || response.data.menus[0]));
      await dispatch(generateCarta(response.data));
      return response.data;
    } catch (error) {
      throw error; // o maneja el error como creas conveniente
    }
  }
);

export const getMenus = createAsyncThunk<any, Menu[], any>(
  'config/getMenus',
  async () => {
    return new Promise((resolve, reject) => {
      apiCall('/config', 'GET')
        .then((response) => {
          resolve(response.data.menus);
        })
        .catch(reject);
    });
  }
);

export const getCategories = createAsyncThunk<any, Category[], any>(
  'config/getCategories',
  async () => {
    return new Promise((resolve, reject) => {
      apiCall('/config', 'GET')
        .then((response) => {
          resolve(response.data.categories);
        })
        .catch(reject);
    });
  }
);

export const getDishes = createAsyncThunk<any, Dish[], any>(
  'config/getDishes',
  async () => {
    return new Promise((resolve, reject) => {
      apiCall('/config', 'GET')
        .then((response) => {
          resolve(response.data.dishes);
        })
        .catch(reject);
    });
  }
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setAppData: (state, action) => {
      Object.assign(state, action.payload);
    },
    updateMenusData: (state, action) => {
      state.menus = action.payload;
    },
    updateUserdata: (state, action) => {
      state.usuario = action.payload;
    },
    updateCategories: (state, action) => {
      state.categories = action.payload;
    },
    updatePlatos: (state, action) => {
      state.dishes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getConfig.fulfilled, (state, action) => {
        state.usuario = action.payload.usuario;
        state.menus = action.payload.menus || [];
        state.categories = action.payload.categories || [];
        state.dishes = action.payload.dishes || [];

        state.menusMap = action.payload?.menus?.reduce(
          (acc, cur) => (acc = { ...acc, [cur.id]: cur }),
          {}
        );
        state.categoriesMap = action.payload?.categories?.reduce(
          (acc, cur) => (acc = { ...acc, [cur.id]: cur }),
          {}
        );

        state.dishesMap = action.payload?.dishes?.reduce(
          (acc, cur) => (acc = { ...acc, [cur.id]: cur }),
          {}
        );
      })
      .addCase(getMenus.fulfilled, (state, action) => {
        state.menus = action.payload;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(getDishes.fulfilled, (state, action) => {
        state.dishes = action.payload;
      })
      .addCase(RESET_STORE, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setAppData, updateUserdata, updateCategories, updatePlatos } =
  configSlice.actions;

export default configSlice.reducer;
