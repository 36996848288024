import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  form: {
    // marginTop: 20,
    // width: '100%',
    margin: '20px 24px',
  },
  section: {
    textAlign: 'left',
  },
  block: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: 24,
  },

  input2Column: {
    gridColumn: 'span 2',
  },
  button: {
    float: 'right',
  },
});
