const FishAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#344D93"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <g transform="matrix(5.5766335,0,0,5.5766335,-980.45196,-1140.5133)">
      <g transform="translate(44.875963,326.33619)">
        <path
          fill="#FFFFFF"
          d="M135.6-100.7l1.3-0.6l-1.5-1.2c0.2-0.3,0.4-0.7,0.7-1c0.6-0.7,1-1.4,1.8-2c0.6-0.5,1.2-1,2-1.4c0.6-0.3,1.2-0.5,1.8-0.7 c0.9-0.2,2-0.5,3.3-0.7c0.5-0.1,1-0.2,1.4-0.5l0.8-1c0.5-0.4,0.9-0.8,1.4-1.1c0.5-0.2,0.8-0.5,1.8-0.6c0.4-0.1,0.7,0,1,0.1 c0.2,0.1,0.4,0.2,0.4,0.5s-0.1,0.6-0.3,0.9l-0.9,1.5c-0.1,0.2-0.2,0.3-0.3,0.6c0.7,0.2,1.5,0.4,2.2,0.8c0.7,0.2,1.3,0.5,1.8,0.8 c0.7,0.3,1.1,0.5,1.6,0.8c0.8,0.5,1.4,0.9,2.1,1.4l0.8,0.7c0.4-0.5,0.6-0.9,1.4-1.6c0.5-0.4,1-0.7,1.8-1c0.6-0.1,1.2-0.3,2-0.3 c0.3,0,0.9,0,1,0.3l-0.9,0.7c-0.4,0.3-0.7,0.7-1.1,1.1c-0.4,0.6-0.6,1.1-0.8,1.4c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.4,0.3,1,0.5,1.5 l1.1,1.3l1.3,1.1c-0.2,0.4-0.2,0.3-0.8,0.6c-0.5,0.1-0.9,0.1-1.4,0.1l-1.1-0.4c-0.4-0.3-1-0.6-1.2-0.9l-1.1-1.1l-0.6-0.8 c-0.2,0.4-0.5,0.7-0.9,1c-0.5,0.4-1,0.7-1.5,1.1l-1.2,0.6l-1.3,0.6c-1,0.4-2,0.7-3,1.1l-1.3,0.4c0,0.3,0.1,0.5,0.3,0.6l0.9,0.7 l0.7,0.6c-0.2,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.7,0-1,0c-0.6-0.2-1.1-0.4-1.4-0.7l-0.6-0.6l-0.6-0.7c-0.4-0.2-0.7-0.2-1.1-0.3 L144-96c-1.1-0.1-2.2-0.2-3.3-0.6c-0.9-0.3-1.7-0.7-2.4-1.3c-0.6-0.5-1.2-1.1-1.6-1.5L135.6-100.7z"
        />

        <path
          fill="#344D93"
          stroke="#344D93"
          strokeWidth={2.1883}
          strokeMiterlimit={175.0706}
          d="M143-103.8c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2C142.5-104.9,143-104.4,143-103.8z"
        />
        <path
          fill="#344D93"
          stroke="#344D93"
          transform="translate(0,0.5)"
          strokeWidth={1.5548}
          strokeMiterlimit={124.3952}
          d="M144.1-96.9l0.7-0.6l0.8-1l0.6-1.2c0.2-0.4,0.3-0.8,0.4-1.2l0.1-1c0-0.4,0-0.9-0.1-1.4s-0.2-1-0.4-1.6l-0.6-1.2l-0.7-1.1l-0.2-0.3l0.5,0.4l0.8,0.8l0.4,0.5l0.6,1l0.3,0.8l0.2,0.6l0.1,0.6v0.8l-0.1,0.8l-0.2,0.8l-0.2,0.7l-0.4,0.7l-0.3,0.5l-0.5,0.5l-0.7,0.6l-0.7,0.5L144.1-96.9z"
        />
      </g>
    </g>
  </svg>
);

export default FishAllergensIcon;
