import './App.css';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { RouterProvider } from 'react-router-dom';
import routes from '@router/routes';
import { Suspense, useEffect } from 'react';
import { Authenticator, View } from '@aws-amplify/ui-react';
import Loader from 'components/Loader';

Amplify.configure(awsExports);

const App = () => {
  useEffect(() => {
    // Función para actualizar la URL del icono en función del tema
    function updateAppleTouchIcon() {
      const isDarkMode = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      const iconElement = document.getElementById('apple-touch-icon');

      // Asegúrate de que iconElement no sea null
      if (iconElement instanceof HTMLAnchorElement) {
        if (iconElement) {
          iconElement.href = isDarkMode
            ? '%PUBLIC_URL%/logo192-dark.png'
            : '%PUBLIC_URL%/logo192-light.png';
        }
      }
    }

    // Llamar a la función para establecer la URL inicial
    updateAppleTouchIcon();

    // Escuchar cambios en las preferencias de color del sistema
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQueryList.addEventListener('change', updateAppleTouchIcon);

    // Función de limpieza para eliminar el event listener
    return () => {
      mediaQueryList.removeEventListener('change', updateAppleTouchIcon);
    };
  }, []);

  return (
    <Authenticator.Provider>
      <Suspense fallback={<Loader height="100vh" />}>
        <View>
          <RouterProvider router={routes} />
        </View>
      </Suspense>
    </Authenticator.Provider>
  );
};

export default App;
