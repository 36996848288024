import { useId } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select } from '@UI';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { setSelectedMenu } from '@store/slices/cartaSlice';

const MenuSelector = () => {
  const styles = useStyles();
  const selectedMenu = useSelector((state) => state.carta.selectedMenu);
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.config.menus);
  const menuOptions = menus.map((el) => ({ label: el.name, value: el.id }));
  const dayId = useId();

  const navigate = useNavigate();

  if (!selectedMenu) return null;

  return (
    <div className={styles.header}>
      <Select
        placeholder="Seleccione un menu"
        defaultValue={selectedMenu.id}
        options={menuOptions}
        onChange={(value) => {
          dispatch(setSelectedMenu(menus.find((el) => el.id === value)));
        }}
        hideErrors
        classes={{
          item: styles.menuSelect,
          container: {
            width: 'max-content',
          },
          control: {
            borderWidth: 0,
            border: 'none',
          },
          singleValue: {
            padding: 0,
            alignItems: 'flex-end',
            fontFamily: 'Raleway Semi Bold',
            fontSize: 46,
            lineHeight: '46px',
          },
        }}
      />
      <div className={styles.timetable}>
        {selectedMenu.allDays ? (
          <span>Lunes a Domingo</span>
        ) : (
          <>
            {selectedMenu.days.map((el) => (
              <span key={`${dayId}-${el}`}>{el}</span>
            ))}
          </>
        )}
        :&nbsp;
        {selectedMenu.allHours ? (
          <span>Todo el día</span>
        ) : (
          <span>{`${DateTime.fromFormat(selectedMenu.from, 'HH:mm').toFormat(
            'HH:mm a'
          )}  - ${DateTime.fromFormat(selectedMenu.to, 'HH:mm').toFormat(
            'HH:mm a'
          )}`}</span>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 24,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            label="Editar"
            onClick={() => navigate(`/dashboard/menu/${selectedMenu.id}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuSelector;
