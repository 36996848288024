const SulfurAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#82104E"
  >
    <circle cx="108" cy="108" r="108" />
    <g className="st1">
      <path
        fill="#FFFFFF"
        d="M89.8,57H75.3v13.5h16.2v4h-21V37.2h20.2v4H75.3V53h14.5V57z"
      />
    </g>
    <g className="st1">
      <path fill="#FFFFFF" d="M114.5,58v3.6h-13.7V58H114.5z" />
    </g>
    <g className="st1">
      <path
        fill="#FFFFFF"
        d="M141.4,74.6l-4.8-8.3c-1.9-3.2-3.2-5.2-4.3-7.4h-0.1c-1.1,2.2-2.1,4.2-4,7.4l-4.5,8.2h-5.5l11.4-18.9l-11-18.5 h5.6l4.9,8.8c1.4,2.4,2.4,4.3,3.4,6.3h0.2c1.1-2.2,2-3.9,3.4-6.3l5.1-8.8h5.5l-11.4,18.2L147,74.6H141.4z"
      />
    </g>
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2052}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={16.412}
      d="M142,181.4 l-0.1-7l33.4-19.9l-0.4-41.3l-33.2-18.8l-0.1-7.6l-5.1,0v7.5L107.8,111L78.5,94.1l0.1-7.2l-4.9-0.1V94l-33.2,19l-0.3,42l33.4,19.4 l0,6.8l5,0.2l0-7.1l28.6-16.8l29.1,16.6l-0.2,7.4L142,181.4z"
    />
    <path
      fill="#82104E"
      stroke="#82104E"
      strokeWidth={0.2052}
      strokeMiterlimit={16.412}
      d="M76.5,103.2l26.6,15.4l0.1,30.8 l-27,16.1L49.1,150l0.1-31.7L76.5,103.2z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2052}
      strokeMiterlimit={16.412}
      d="M54.3,120.2l2.6,4.5l20.6-12 l-2.5-4.4L54.3,120.2z"
    />
    <path
      fill="#82104E"
      stroke="#82104E"
      strokeWidth={0.2052}
      strokeMiterlimit={16.412}
      d="M139.8,101.6l26.6,15.4 l0.1,30.8l-27,16.1l-27.1-15.6l0.1-31.7L139.8,101.6z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2052}
      strokeMiterlimit={16.412}
      d="M117.2,120.2l2.6,4.5l20.6-12 l-2.5-4.4L117.2,120.2z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2052}
      strokeMiterlimit={16.412}
      d="M157.2,122.3l4.9,0.1l-0.1,24.9l-21.7,12.9l-2.5-4.5l19.3-10.9 L157.2,122.3z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2052}
      strokeMiterlimit={16.412}
      d="M94,122.3l5.4,0.1l-0.1,24.9 l-21.7,12.9l-2.9-4.2l19.3-11L94,122.3z"
    />
    <path
      fill="#FFFFFF"
      stroke="#82104E"
      strokeWidth={0.7786}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M53.3,116.9c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6c0-4.2,3.4-7.6,7.6-7.6C49.8,109.3,53.3,112.7,53.3,116.9z"
    />
    <path
      fill="#FFFFFF"
      stroke="#82104E"
      strokeWidth={0.7786}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M52.9,151.5c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6s3.4-7.6,7.6-7.6C49.5,143.9,52.9,147.3,52.9,151.5z"
    />
    <path
      fill="#FFFFFF"
      stroke="#82104E"
      strokeWidth={0.7786}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M115.4,153.2c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6s3.4-7.6,7.6-7.6C112,145.6,115.4,149,115.4,153.2z"
    />
    <path
      fill="#FFFFFF"
      stroke="#82104E"
      strokeWidth={0.7786}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M178.4,152.5c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6s3.4-7.6,7.6-7.6C175,144.8,178.4,148.2,178.4,152.5z"
    />
  </svg>
);

export default SulfurAllergensIcon;
