import { colors } from 'assets/scss/variables/variables';

const DishImagePlaceholder = ({
  height = 355,
  width = 355,
  color = colors.black,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 355 355" fill={color}>
      <g>
        <g>
          <path
            d="M349.805,45.295c-2.924-1.098-6.221-0.273-8.279,2.074c-1.133,1.289-27.742,32.117-29.613,77.371
			c-1.188,28.682-1.164,46.834,9.152,57.029c4.523,4.473,10.537,6.934,18.598,7.543v113.035c0,4.143,3.357,7.5,7.5,7.5
			c4.142,0,7.5-3.357,7.5-7.5V181.935c0-0.039,0-0.076,0-0.115V52.314C354.662,49.191,352.729,46.396,349.805,45.295z
			 M339.662,174.246c-3.695-0.424-6.355-1.467-8.055-3.145c-5.875-5.809-5.635-23.377-4.709-45.742
			c0.791-19.137,6.758-35.553,12.764-47.471V174.246z"
          />
          <path
            d="M180.502,58.058c-65.766,0-119.271,53.506-119.271,119.275c0,65.764,53.506,119.27,119.271,119.27
			c65.768,0,119.272-53.506,119.272-119.27C299.774,111.564,246.27,58.058,180.502,58.058z M180.502,281.603
			c-57.494,0-104.271-46.777-104.271-104.27c0-57.498,46.777-104.275,104.271-104.275c57.496,0,104.272,46.777,104.272,104.275
			C284.774,234.826,237.998,281.603,180.502,281.603z"
          />
          <path
            d="M180.502,92.883c-46.563,0-84.445,37.883-84.445,84.447s37.883,84.447,84.445,84.447s84.445-37.883,84.445-84.447
			S227.065,92.883,180.502,92.883z M180.502,246.777c-38.293,0-69.445-31.154-69.445-69.447s31.152-69.447,69.445-69.447
			s69.445,31.154,69.445,69.447S218.795,246.777,180.502,246.777z"
          />
          <path
            d="M52.111,46.679c-4.143,0-7.5,3.357-7.5,7.5v35.482h-7.307V54.179c0-4.143-3.357-7.5-7.5-7.5c-4.142,0-7.5,3.357-7.5,7.5
			v35.482H15V54.179c0-4.143-3.357-7.5-7.5-7.5c-4.142,0-7.5,3.357-7.5,7.5v42.982c0,26.16,9.182,46.871,22.305,52.615v150.705
			c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V149.777c13.125-5.744,22.307-26.455,22.307-52.615V54.179
			C59.611,50.037,56.254,46.679,52.111,46.679z M29.805,136.375c-5.244,0-12.877-12.381-14.496-31.713h28.992
			C42.682,123.994,35.049,136.375,29.805,136.375z"
          />
        </g>
      </g>
    </svg>
  );
};

export default DishImagePlaceholder;
