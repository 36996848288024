import { createApi } from '@reduxjs/toolkit/query/react';
import { apiCallRTK } from '@utils/apiTools';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: apiCallRTK,
  tagTypes: ['Config', 'Menu', 'Category', 'Dish'],
  keepUnusedDataFor: 120,
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: () => ({ url: `/config` }),
      // providesTags: (result, error, id) => [
      //   ...(result
      //     ? result.data.map((el) => ({
      //         type: 'Config',
      //         id: el.id,
      //       }))
      //     : []),
      //   { type: 'Activity', id: 'PARTIAL-LIST' },
      // ],
    }),
  }),
});

export const { useGetConfigQuery } = configApi;
