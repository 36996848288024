const SesameAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#9A8E6C"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M123.5,95.9l1.6-2.1l2.8-2.6l3.8-2.8l5.9-4.5l4.9-3.2l4.4-2.7l4.6-2.6l6.2-2.8l6.5-2.2l3.1-0.4l2.2,0.2l1.1,0.8 c-10,11.1-20.2,22-31.1,32.9c0.4,0.8-0.2,2.4,1.9,1.7C151.8,95,162.1,84.1,172,72.9l0.3,2.9c-0.1,1.6-0.3,3.4-0.6,5.5l-1.4,7.1 l-2.6,6.6l-6.6,14l-3.8,6.7l-2.6,4.1l-2.8,3.2l-3.8,3.4l-4.5,3.3l-6.5,2.3c-2.3,0.7-4.5,1.2-6.8,0.8l-5.2-1.6l-4-2.2 c-1.7-1.7-3-3.7-4.5-5.5c-1.1-2.5-1.6-4.8-1.6-6.9l0.3-6.2c1.1-3.5,3.1-7.6,4.3-9.3L123.5,95.9z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2364}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M84.9,108.1l-1.3,1.8l-2.3,2.2l-3.2,2.3l-5,3.8l-4.2,2.7l-3.7,2.2l-3.9,2.1l-5.2,2.3l-5.4,1.7l-2.6,0.3l-1.9-0.2l-0.9-0.7 c8.5-9.3,17.3-18.6,26.6-27.7c-0.3-0.7,0.2-2.1-1.6-1.5c-9,9-17.8,18.2-26.2,27.7l-0.2-2.5c0.1-1.4,0.3-2.9,0.6-4.7l1.3-6.1l2.3-5.6 l5.7-11.9l3.3-5.7l2.2-3.5l2.4-2.7l3.2-2.8l3.8-2.7l5.4-1.9c1.9-0.5,3.8-1,5.7-0.6l4.3,1.4l3.3,2c1.4,1.5,2.5,3.2,3.7,4.8 c0.9,2.2,1.3,4.2,1.2,6l-0.4,5.3c-1,3-2.7,6.5-3.8,7.9L84.9,108.1z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.199}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M101,60.3l-0.8-1.8l-0.7-2.8l-0.5-3.5l-1-5.6l-0.5-4.5l-0.3-3.9l-0.2-4l0.2-5.2l0.6-5.2l0.6-2.3l0.8-1.5l0.8-0.5 c3.7,10.6,7.3,21.4,10.5,32.6c0.6,0,1.4,0.9,1.6-0.7c-3.3-10.9-6.8-21.7-10.6-32.2l1.8,0.8c0.9,0.6,2,1.3,3.1,2.3l3.8,3.3l3.2,4 l6.4,9.2l2.9,4.9l1.7,3.2l1.1,3l0.9,3.8l0.6,4.2l-0.5,5.2c-0.3,1.8-0.6,3.6-1.5,5l-2.4,3l-2.5,2c-1.5,0.6-3.1,0.8-4.6,1.2 c-1.8-0.1-3.3-0.5-4.6-1.3l-3.6-2.3c-1.7-1.9-3.6-4.7-4.3-6.1L101,60.3z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.2227}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M100.6,180.3l-1.2-1.6L98,176l-1.2-3.4l-2-5.3l-1.2-4.3l-1-3.8l-0.8-3.9l-0.5-5.1l0-5.2l0.5-2.3l0.7-1.6l0.9-0.6 c6.2,9.9,12.2,20,17.9,30.5c0.7-0.1,1.9,0.7,1.9-0.9c-5.8-10.2-11.7-20.3-18-30.2l2.4,0.6c1.3,0.5,2.7,1.1,4.2,1.8l5.3,2.8l4.5,3.5 l9.4,8.2l4.4,4.4l2.6,2.9l1.8,2.8l1.7,3.6l1.4,4l0.1,5.2c-0.1,1.8-0.2,3.6-1.2,5.1l-2.6,3.3l-2.9,2.3c-1.8,0.8-3.7,1.2-5.6,1.8 c-2.3,0.1-4.3-0.1-5.9-0.6l-4.9-1.8c-2.5-1.7-5.2-4.1-6.3-5.4L100.6,180.3z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeWidth={0.1493}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M132.9,0h-14.8c0.9,0.6,1.7,1.2,2.6,1.9c0.6,1.1-0.2,1-0.5,1.3c-1.4-1.1-2.9-2.2-4.3-3.2h-10.4l0,0.1l1.8,2.3 l1.8,2.2l2.1,2.4l2.9,2.8l1.8,1.8l1.6,1.3l1.2,0.6l2.9,1.4c1,0.5,3.1,1.1,4.9,1.3l2.9-0.6c1-0.2,1.9-0.9,3-1.8 c0.7-1.1,1.4-2.2,1.9-3.4l0.5-2.6l0-3.2c-0.1-1.4-0.7-2.6-1.3-3.9L132.9,0z"
    />
  </svg>
);

export default SesameAllergensIcon;
