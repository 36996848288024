const CeleryAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216.1 216.1"
    fill="#54BF36"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <g transform="translate(-165.22728,296.43125)">
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M316.2-249.7l-0.2-1.8l-0.6-1.2l-0.9-1.2l-0.9-0.6l-1-0.5 l-1.6-0.3l-4-0.1l-2.5-0.3l-0.7-0.3l0.9,0.9l0.6,1l0.7,1.4l0.2,1.4l-0.1,1.5l-0.5,1.1l-0.2,0.9l0.3,1l1.2,0.7l1.5,0.7l1.9,0.4 l3.3-0.5l1.4-0.9l0.7-0.9l0.3-1L316.2-249.7z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M251.8-249.4l1.6-0.6l0.7-0.9l0.4-0.9l0.1-1.1l-0.2-1.2 l-0.5-1.4l-0.6-2.3l-0.3-1l-0.1-1.6l0.4-1.2l0.9-0.5l1.1-0.5l1.2-0.3l1.4,0.1l1.3,0.5l1.2,0.9l1.2,0.6l1.6,0.6l1.6-0.1l1.2-0.5 l1.2-1l1.5-1.9l1-1.3l1.2-1.2l1.2-0.7l1.6-0.3l1.5,0.2l2.9,1.1l1.7,0.5l1.6,0.2h1.1l1.7-0.6l1.9-0.6l2.4-0.5l1.7,0.1l1.8,0.7l1.8,1 l1.3,1.4l1.6,2.2l1.2,1.9l1.1,2.1l-1.1-0.2l-3.2-0.1l-1.8,0.1l-1.6,0.2l-1.8,0.4l-2.2,0.8l-1.7,0.7l-0.7,0.8l-0.4,1.2v1l0.3,1.2 l0.7,2.3l-2.8-1.1l-2.3-1.1l-2.8-1.3l-3-1.2l-1.9-0.6l-2.7-0.4l-1.9,0.1l-1.7,0.9l-1.3,1.3l-0.5,1.9l-0.4,3l-0.5,2.4l-0.9,2.2 l-1.2,0.5l-1.6-0.2l-1.9-0.9l-2.6-1.8l-2.8-2.6L251.8-249.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M242.1-236.8 v-3.3l0.4-2.6l2.1-3.2l1-1.9c0.2-0.4,0.2-1,0.2-1.8c-0.5-0.8-1.1-1-1.8-1.3l-2.8-0.9c-1.1,0.1-2.3-0.1-3.1,0.4 c-1.7,0.8-1.5,1.1-1.9,1.6l-1.3,3.3l-1,1.5l-1.3,0.8l-1.8,0.5c-0.1,0.3-0.4,0.6,0.1,0.7l1.7,1.9c0.9,0.9,1.6,1.9,1.7,3.2l0,1.7 c-0.1,1.1-0.7,2-1.2,2.9l-1,1l2.3-0.6l2.8-0.6l2.4-1l1.7-1L242.1-236.8z"
      />

      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M248.9-247.8l-1.6,2.7l-1,2.9l-0.4,2.5l-0.4,2.2l-0.3,1.9l-0.8,1.2l-1.1,1.3l-1.2,0.6l-2.2,1.4l-2.1,0.5l-3,0.8l-3.1,0.6l-2.2,0.5 l-1.5,0.5l3,1.3l2.9,1l3.2,1l4.7,1.5l4.1,0.8l1.6,0.9l2.2,3.4l1.3,1l1.3,0.6h2.2l1.2,0.6l1.1,0.8l0.5,1.5l-0.5,1.7v1.3l0.3,0.7 l1,0.6l0.4,7.6l0.1,19.6l-1,7.1l-1.1,13.8l-0.8,8.3l-0.4,8.9v5.3l0.4,7.7l1.1,4.8l1.7,3.5l1.5,1.9l0.6,0.3l1.8,4.1l2.1,2.7l2.5,1.9 l2,1.1l0.1,0.3l2.5,0.4l3.6,0.4l3.5-0.2l1.4-1l0,0l1.8-1.1l1.6-2.2l1.5-1.9l0.9-1.5c1.3-0.8,2.1-0.9,4-2.9l2-3.5l1.4-3.7l1-4.8 l0.4-4.7l-0.4-7.9l-0.5-7.5l-0.4-6l-0.3-5.8l-0.2-8.2l0.4-15.2l-0.5-12.9v-7.4l-0.2-5.7l0.6-0.1l0.7,0.6l1.2,0.4l0.8,0.2l0.3-0.3 l1.5-1.9l1.8-1.9l1-1.3l0.2-0.8v-0.8l0.2-0.6l0.9-1.6l2-1.7l2.1-1.4l2.4-1.2l2.9-1.2l2.4-1.4l1.8-1.2l1.3-1l0.7-0.8l0.6-0.8h-1 l-1.9-0.3l-1.6-0.5l-1-0.7l-0.4-0.7l-0.1-1.2l0.8-1.8l1.8-2.4l1.5-1.8l0.6-1l0.4-0.8l0.2-0.6l-0.9,0.4l-1.4,0.4l-1.8,0.4l-1.7,0.1 l-1.4,0.1l-2-0.1l-1.4-0.3c0,0-1-0.9-1-0.4c0,0.4-1.5-0.9-1.5-0.9l-1.1-1.2l-0.5-1.5v-1.4l0.4-2.3l0.3-1.4l-0.4-1l-0.9-0.9 l-2.2-0.7l-2.1-0.3h-2.7l-2.2,0.1l-1.8,0.3l-1.5,0.7l-0.9,1.4l-0.1,2.2l0.3,1.8l-0.4,1.5l-1.1,0.4l-1.8-0.2l-2.7-0.8l-2.6-0.9 l-3.1-1.2l-2.8-1.1l-1.9-0.4l-1.4-0.2l-1.1,0.1l-1.7,0.4l-1.1,1.2l-0.3,1.9l-0.4,2.4l-0.3,1.8l-0.6,1.4l-0.9,1.2l-1.2,0.9l-1.4,0.3 h-1.9l-1.8-0.5l-2.3-1.3l-2.5-1.7l-1.8-1.7l-1.3-1.2l-1.4-1.1L248.9-247.8z"
      />
      <path
        fill="#54BF36"
        stroke="#54BF36"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M285.9-224.7l-0.4-5.5l-0.2-4.5l-0.2-1.2l-0.6,1.5l-0.5,1.7 l-0.4,1.7l-0.3,1.8l-0.1,1.5l0.2,2.1l0.2,4l-0.1,3.9l-0.2,5.8l0.4-0.1l-0.1,2.8l0.2,4.2l0.2,2.5l0.3,4.6l0.8,13.7l-0.2,5.9 l-0.2,4.7l0.1,4l-0.1,2.2l0.1,9.9l0.6,8.7l0.3,4.9l0.3,2.7l1,14.8l0.2,1.7l-0.2,2.1l-0.3,1.2l-0.1,1.1l1.2-0.9l1-1.1l0.5-0.8 l0.5-0.9l0.6-2l0.6-3.3l0.5-8.3v-5.8l-0.3-7.1l-0.4-7.4l-0.3-5l-0.4-6.1l-0.2-2.1v-5.4l-0.1-4.3l-0.2-4.8l-0.4-21.2v-6.5l-0.2-2 l-0.3-2.4l-0.2-2.5v-1.8l-0.2-1.3l-0.2-0.7l-0.3-0.4l-0.4-0.1l-0.6-0.2h-0.3L285.9-224.7z"
      />
      <path
        fill="#54BF36"
        stroke="#54BF36"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M259.7-232.8l2.4,1.3l2.2,0.9l1.3,0.4l1.5,0.1l0.9-0.5 l0.1-1.1l-0.4-1.2l0.2-0.3l1.4,1.8l1.9,2.7l3.4,4.9l1.8,2.9l2.5,3.6l0.9,2.4l0.7,2.3l0.3,1.9l0.4,0.2v2.3l0.2,2.3l0.2,6l-0.4,11.7 l-0.4,15.2l0.6,8.7l1.1,17.5l0.5,8.6l0.2,10.6l-0.4,5.5l-1.4,4.1l-1.1,1.7l-1.6,1.3l-2.3,1.1l-1.7,0.6h-2.4l-1.9-0.8l-2.6-2l-2-3.1 l-1.5-4.6l-0.4-5l0.1-4l0.1-6l0.3-3l0.4-8.5l1.2-9.7l1.3-10.8l0.7-8l0.2-4.4l0.1-1.9v-1.8l-0.1-2.6l0.6,3.9l0.2,3.8l0.5,4.8 l0.4,3.7l0.9,9.7l0.7,7.8l0.6,7.8l0.3,3.1v5v3l-0.1,4.7l-0.3,4.8l0.5,0.8l0.6,0.3l0.7-0.1l0.6-0.4l-0.1-0.4l0.4-8.1l-0.1-4.8 l0.1-2.2l-0.4-8.3l-0.8-9.8l-0.8-8.8l-1.7-16.6l-0.7-9.1l-0.7-6.5l-0.1-0.5l-0.2-0.1l0.7-7.4l0.5,0.2l-0.2-0.6l-0.3-0.8l0.1-1.3 l-0.2-2.6v-1.4l-0.2-2.2l-0.8-2.7l-0.9-2.3l-1.5-2.6l-2-2.7l-1.9-1.8l-1.3-1.2L259.7-232.8z"
      />

      <path
        fill="#54BF36"
        stroke="#54BF36"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M260.3-125.4l-0.2-6.5l0.4-9.8l2.7-17.7l2.5-16l0.6-6v-4.4v-1.5l0.4-22.9l-1.2-4.4l-1-1.5l-1.2-1.9l-1.6-1.9l-2.5-1.9l0.9,2.4 l1,1.6l1,1.8v1.9l0.1,1.7l-0.2,1.8l-0.4,1.3l0.3,1l0.3,2.8l0.1,3.7l0.3,6.1l0.1,5.2l-0.3,6.6v2.9l-1.2,12.5l-0.9,5.8l-0.5,6.2 l-0.4,3.1l-0.3,8l-0.2,6.5l-0.2,4l0.2,4.2l0.3,2.5l0.6,2.1L260.3-125.4z"
      />
    </g>
  </svg>
);

export default CeleryAllergensIcon;
