export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const convertToBase64 = (files) => {
  return new Promise((resolve, reject) => {
    let filesData = [];
    let loadedFiles = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (event) => {
        filesData.push(event.target.result);
        loadedFiles++;

        if (loadedFiles === files.length) {
          resolve(filesData);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    }
  });
};
