import { useEffect, useState } from 'react';
import classnames from 'classnames';

const Banner = ({ children, classes, close }) => {
  const [counter, setCounter] = useState(null);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    const timer =
      counter >= 0 && setInterval(() => setCounter((s) => s - 1), 1000);
    if (counter === 0) setClosing(true);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (closing) setTimeout(() => close(), 1000);
  }, [closing, close]);

  return (
    <div
      className={classnames(`banner ${classes}`, {
        'banner-close': closing,
      })}
    >
      <div
        className="banner__close"
        onClick={() => {
          setClosing(true);
        }}
      />
      {children}
    </div>
  );
};

export default Banner;
