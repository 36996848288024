import Table from 'components/Table';
import useAPI from '@hooks/useAPI';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '@store/slices/configSlice';
import Button from '@UI/Button';
import { ulid } from 'ulid';
import { useGetDishesQuery } from '@store/services/dishService';
import styles from './DishesList.module.scss';
import { CircularProgress } from '@mui/material';
import DishCard from '@features/Dish/components/DishCard/DishCard';

const DishesList = () => {
  const { data, isLoading } = useGetDishesQuery();
  const dishes = data?.data.dishes || [];

  const { apiCall } = useAPI();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateItem = () => navigate(`d_${ulid()}`);

  const handleDragEnd = ({ source, destination }) => {
    if (!destination) return;
    reorderData(source.index, destination.index);
    apiCall(`/dish/${dishes[source.index].id}`, 'PATCH', {
      order: destination.index,
    })
      .then(() => dispatch(getCategories()))
      .catch((err) => console.error('## ERROR on POST /carta/menu: ', err));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Ver',
        Cell: (props) => (
          <Button
            label="Ver"
            variant="link"
            color="primary"
            onClick={() => navigate(`${props.row.original.id}`)}
          />
        ),
      },
      {
        Header: 'Foto',
        accessor: 'photo',
      },
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Precio',
        accessor: 'price',
      },
      {
        Header: 'Menus',
        accessor: 'menus',
      },
      {
        Header: 'Categorias',
        accessor: 'categories',
      },
      {
        Header: 'Usado en',
        accessor: 'usedIn',
      },
      {
        Header: 'Contiene',
        accessor: 'contains',
      },
      {
        Header: 'Última actualización',
        accessor: 'updated_at',
      },
    ],
    [navigate]
  );

  const reorderData = (startIndex, endIndex) => {
    const newData = [...dishes];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
  };

  if (isLoading) return <CircularProgress size={80} />;

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>Platos</h3>
        <Button label={'+ Nuevo plato'} onClick={handleCreateItem} />
      </div>
      <div className={styles.body}>
        {/* <Table
          columns={columns}
          data={dishes}
          reorderData={reorderData}
          handleDragEnd={handleDragEnd}
          classes={{
            cell: styles.tableCell,
          }}
          draggableRows
        /> */}
        {dishes.map((dishData) => (
          <DishCard dishData={dishData} />
        ))}
      </div>
    </>
  );
};

export default DishesList;
