const MollusksAllergensIcon = ({ size = 216 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 216 216"
    fill="#4DC4D5"
  >
    <ellipse cx="108" cy="108" rx="108" ry="108" />
    <path
      fill="#F9F9F9"
      stroke="#F9F9F9"
      strokeWidth={0.2788}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={22.3065}
      d="M91.8,44.8l-5.1,0.8c-2.6,0.6-4.8,1.8-6.1,3.2l-4.6,3.8L71.9,56l-5,2.3l-5,1.1L55.7,61l-5,4.7l-3,5.8L44.5,78 c-0.8,1.1-2.4,2-4.7,2.8c-1.9,1.1-2.2,1.3-4.6,3.5c-1.3,1.5-2.2,3.4-2.2,6.3l0.5,4.9l1,3.6c-0.9,1.3-0.2,3.5,0.1,5.5 c0.5,3.3,1.2,6.5,2.7,9.1c1.9,3.9,5.5,7.7,8.6,11.6L56,135l11.7,9.9l8.9,6.2l-1.5,3.5l-3.5,5.3c-0.9,1.4-1.4,2.8-1.2,4.2 c0.1,3.1,1.4,4.6,2.7,5.9c2.8,0.9,5.7,1.5,8.5,1.1h56l4.7-1.8c1.5-0.6,2.5-1.8,2.7-3.9c0.2-1.5,0.1-3-0.5-4.2c0-3.6-3.8-6.7-5.8-10 c4.4-2.6,8.9-4.8,12.6-8.9l13.3-11.7l9.2-9.2l4.2-6.5l2.3-5.4l1.1-5.2l0.1-3.3v-1.5l0.2-3.8c0.4-0.8,0.6-3.6,1-5.2 c0.3-1.8-0.8-3.6-1.7-5.4l-1.8-2.6l-4.3-1.9l-3.9-2.5l-1.4-2.5c0.3-1.4-1.8-3.8-2.4-5.5c-0.7-1.8-2.8-4.4-4.7-7l-3.2-2.2l-6.5-2.1 l-5-1.2l-5.8-2.7l-5-4.5c-0.9-1.4-4.1-2.8-6.7-4.2l-3.5-1.2h-5.8l-8.2,2l-3.5,0.3l-5.8,0l-6.2-1.5L91.8,44.8z"
    />
    <g id="g3974">
      <path
        fill="#4DC4D5"
        stroke="#4DC4D5"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M54.9,83.8l-1,1L52.8,86l-0.9,0.8l-1.1,0.8l-1.3,0.7l-1.2,0.6 l37.7,38.2L54.9,83.8z"
      />
      <path
        fill="#4DC4D5"
        stroke="#4DC4D5"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M81.6,62.7l-1.1,0.9l-1,0.9l-1.1,0.7l-1,0.6L77,65.9l-0.7,0.3 L75.1,67l-1.2,0.3l23.9,57.5L81.6,62.7z"
      />
      <path
        fill="#4DC4D5"
        stroke="#4DC4D5"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M106.4,56.6l1.4,0.4l1,0.1h0.8l1.3-0.1l1.2,0.1l1.3-0.1 L114,57l0.9-0.2h0.3l-4.6,69.8L106.4,56.6z"
      />
      <path
        fill="#4DC4D5"
        stroke="#4DC4D5"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M140,62.7l1.2,1l1,0.8l1.2,0.7l1.1,0.4l0.3,0.1l0.8,0.7l1,0.4 l1.2,0.5l-24,57.6L140,62.7z"
      />
      <path
        fill="#4DC4D5"
        stroke="#4DC4D5"
        strokeWidth={0.2788}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={22.3065}
        d="M166.7,83.8l1,1l0.9,1.2L170,87l1.6,1l1.6,0.8l-38,38.6 L166.7,83.8z"
      />
    </g>
  </svg>
);

export default MollusksAllergensIcon;
