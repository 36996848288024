import DishIcon from '@UI/Icons/DishIcon';
import styles from './DishPhotoPlaceholder.module.scss';

const DishPhotoPlaceholder = () => {
  return (
    <div className={styles.placeholder}>
      <DishIcon size={80} />
      <span className={styles.instructions}>Añade foto. ¡Arrástrala aquí!</span>
      <span className={styles.maxsize}>
        Imágen máximo 1MB : jpeg, jpg, png, gif. Solo 1 foto.
      </span>
    </div>
  );
};

export default DishPhotoPlaceholder;
